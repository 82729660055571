import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
// Updates Database when Network available, Queue when offline. Updates Context API
import { useOfflineFetch } from '../libs/offlineFetch';

const ShareModal = () => {
  const {
    user,
    jwt,
    userPrograms
  } = useContext(AppStateContext);
  const [open, setOpen] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');

  const domain = "https://api.templatetrainer.com";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Updates the Workout History in DB and Workout History in React Context Api
  const offlineFetch = useOfflineFetch();

  const handleShare = async () => {
    console.log(selectedProgram);
    console.log(recipientEmail);
    try {
      const data = {
        program: selectedProgram,
        recipientEmail: recipientEmail,
        sendersEmail: user.email
      };
      const url= domain + "/shareProgram";
      const options =  {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`
        },
        credentials: 'include',
        body: JSON.stringify(data),
      };


      const response = await offlineFetch(url, options, 'pendingRequests', data);
      // Handle success and show a notification to the user
      console.log('Program shared successfully:', response.data);

      // Close the modal
      handleClose();
      const sharedSuccess = await response.json();
      // Process the user data or perform actions based on the response
      return sharedSuccess;
    } catch (error) {
        console.log(error);
        // Handle error scenario, display an error message, etc.
        // Handle error and show an error message to the user
        console.error('Error sharing program:', error);
        throw error;
      }
  };

  return (
    <div>
      <button className="dashboard-btn" onClick={handleOpen}>
        Share
      </button>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: 'none', padding: '15px 20px', backgroundColor: '#a10404' , borderRadius:"5px"}}>
          <Modal.Title style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>Share A Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <Form.Label>Select Program</Form.Label>
              <FormControl as="select" value={selectedProgram.programID ? selectedProgram.programID: ''} onChange={(e) => setSelectedProgram(userPrograms.userPrograms.find((program) => program.programID === e.target.value))}>
              <option value="">Select a program</option>
              {userPrograms && userPrograms.userPrograms.map((program) => (
                <option key={program.programID} value={program.programID}>
                  {program.programName}
                </option>
              ))}
              </FormControl>
            </FormGroup>
            <FormGroup>
              <Form.Label>Recipient Email</Form.Label>
              <Form.Control type="email" value={recipientEmail} onChange={(e) => setRecipientEmail(e.target.value)} />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={handleShare}><FontAwesomeIcon icon={faPaperPlane} />Share</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShareModal;
