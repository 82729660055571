import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Form, Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";
import { AppStateContext } from "../libs/AppStateContext";
import ResistanceExercise from "./ResistanceExercise.js";
import CircuitExercise from "./CircuitExercise.js";
import Cookies from "js-cookie"; // Import the js-cookie library
import { useOfflineFetch } from '../libs/offlineFetch';

// Workout selected on Dashboard
function Workout(props) {
  // Globale State from REACT CONTEXT API
  const {
    userWorkoutHistory,
    jwt,
    updateUserWorkoutHistory,
    isAuthenticated
  } = useContext(AppStateContext);
  const domain = "https://api.templatetrainer.com";

  // history is passed in by react router. keeps record of pages visited
  const [history] = useState(props.history);
  // Don't allow non-logged in users to access any pages other than login
  if (!isAuthenticated) {
    // redirect to login
    history.push("/");
  }
  // Workout component local state.
  const [program] = useState(props.location.state ? props.location.state.program:{});
  const [workout, setWorkout] = useState(props.location.state ? props.location.state.workout : {});
  const [currentWorkoutScheduleIndex,setCurrentWorkoutScheduleIndex] = useState(null);
  console.log(props.location.state ? props.location.state :"No State");
  // start at top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    // See if current workout has workout schedule or logic specified in program.exerciseLogic
    if(program.workoutSchedule.length>0){
    // Find last workout performed in the program to get currentWorkoutScheduleIndex. userWorkoutHistory may be undefined. Need to check
    const workoutHistory = userWorkoutHistory.userWorkoutHistory ? [...userWorkoutHistory.userWorkoutHistory] : [];
    let lastIndex = -1;
    for (let i = workoutHistory.length - 1; i >= 0; i--) {
      if (workoutHistory[i].programID === program.programID) {
        lastIndex = i;
        console.log("workout was found");
        console.log(workoutHistory[i]);

        break;
      }
    }
    // No workout history found
    if (lastIndex !== -1) {
      const lastMatchingObject = workoutHistory[lastIndex];
      // If last workout was the last one in workout schedule, reset the workout schedule index to 0
      let currentScheduleIndex = lastMatchingObject.workoutScheduleIndex + 1 === program.workoutSchedule.length?
      0:lastMatchingObject.workoutScheduleIndex + 1;
      // Check if the selected workout is the correct one in the workout schedule
      if (program.workoutSchedule[currentScheduleIndex] === workout.workoutID){
        console.log("Setting current schedule index in useeffect");
      }else{
        // Don't update the program. Wrong workout clicked
        currentScheduleIndex = lastMatchingObject.workoutScheduleIndex;
      }
      setCurrentWorkoutScheduleIndex(currentScheduleIndex);
      console.log(currentScheduleIndex);
      // Set Workout values from previous workout with this same id
      let lastWorkout = -1;
      let currentWorkout = {...workout};
      console.log(workout);
      // Must look through workout history once more for the most current exercise set data for each exercise
      for (const exercise of currentWorkout.workoutExercises) {
        outerLoop: for (let i = workoutHistory.length - 1; i >= 0; i--) {
          for (const ex of workoutHistory[i].workoutExercises) {
            if (ex.exerciseID === exercise.exerciseID) {
              // Update the exercise properties directly
              // Deal with the case of more or less sets if getting sets from another workout:
              if(workoutHistory[i].workoutID !== currentWorkout.workoutID){
                if (ex.sets.length === exercise.sets.length) {
                  exercise.sets = ex.sets; // Copy the entire ex.sets array
                } else if (ex.sets.length < exercise.sets.length) {
                  // copy the values of prev exercise and add the rest of the sets from program (vals depend on initial vals set in program editor).
                  // Assuming ex.sets and exercise.sets are arrays
                  for (let i = 0; i < ex.sets.length; i++) {
                    if (i < exercise.sets.length) {
                      exercise.sets[i] = ex.sets[i];
                    } else {
                      exercise.sets = exercise.sets.concat(ex.sets.slice(i));
                      break;
                    }
                  }
                } else if (ex.sets.length > exercise.sets.length) {
                  exercise.sets = ex.sets.slice(0, exercise.sets.length);
                }
              }else{
                exercise.sets = ex.sets;
                exercise.notes = ex.notes;
              }
              console.log(workoutHistory[i].workoutName);
              // Add other properties as needed
              break outerLoop; // This will break out of both loops when the exercise is found.
            }
          }
        }
      }      
      const logicSet = program.exerciseLogic.find((someWorkout) => someWorkout.applyToWorkoutScheduleIndex === currentScheduleIndex);
      // Don't run logic if wrong workout was clicked.
      if (logicSet !== undefined && program.workoutSchedule[currentScheduleIndex] === workout.workoutID) {
        // Logic Analyzer needs logic object in program.exerciseLogic, the current workout, and workout history which is array
        const performLogicAnalysis = async () => {
          let programCopy = JSON.parse(JSON.stringify(program));
          const logic = await callLogicAnalyzer(logicSet, workout, workoutHistory, programCopy);
          console.log("Logic")
          console.log(logic)
          // Update the current exercise values in workout component and rerender.
          if(logic){
            setWorkout(logic);
          } else{
            if(currentWorkout){
              console.log("Logic Error. Use Prev vals if any, or 0 vals");
              // No Logic returned for some reason. Set the values from the last time the exercise was performed (even if it was a different workout)
              setWorkout({
                workoutID: currentWorkout.workoutID,
                workoutName: currentWorkout.workoutName,
                workoutExercises: currentWorkout.workoutExercises
              });
            }
          }
        };
  
        performLogicAnalysis();

      } else{
        if(currentWorkout){
          // No Logic. Set the values from the last time the exercise was performed (even if it was a different workout)
          console.log("No Logic. Set the values from the last time the exercise was performed (even if it was a different workout)");
          setWorkout({
            workoutID: currentWorkout.workoutID,
            workoutName: currentWorkout.workoutName,
            workoutExercises: currentWorkout.workoutExercises
          });
        }
      }
    } else {
      // lastIndex not found in workout History
      console.log("lastIndex not found in workout History");
      setCurrentWorkoutScheduleIndex(0);
    }
  }else{
    // no Workout schedule
    console.log("No workout schedule")
    setCurrentWorkoutScheduleIndex(0);
  }
}, []);

const [authToken, setAuthToken] = useState("token"); // State to store the token

useEffect(() => {
  // Read the token from the cookie
  const token = Cookies.get("token");
  setAuthToken(token?token:"Token Missing"); // Store the token in state
  console.log(token);
  const allCookies = Cookies.get(); // Get all cookies as an object
  console.log(allCookies);
  const tokenz = document.cookie.split('; ').find((row) => row.startsWith('token='));
  const authTokenz = tokenz ? tokenz.split('=')[1] : null;
  const otherCookie = document.cookie
  console.log(otherCookie);
}, []);
  
  // Updates the Workout History in DB and Workout History in React Context Api
  const offlineFetch = useOfflineFetch();
  const updateDbWorkouts = async (userWorkout) => {
    const url = domain+"/updateWorkoutHistory";
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwt}`
      },
      credentials: 'include', // Set this to true to include cookies in the request
      body: JSON.stringify(userWorkout),
    };
  
    try {
      console.log('Updating workout history in the database:', userWorkout);
      const userWorkoutData = await offlineFetch(url, options, 'userWorkoutHistory', userWorkout);
      // Handle the response data as needed
    } catch (error) {
      console.log(error);
      // Handle error scenario, display an error message, etc.
      throw error;
    }
  };
  
  // Function to call the API and perform the logic analysis
  async function callLogicAnalyzer(logicSet, workout, workoutHistory, programCopy) {
    try {
      const response = await fetch(domain + "/logicAnalysis", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        credentials: 'include',
        body: JSON.stringify({
          logicSet,
          workout,
          workoutHistory,
          programCopy
        })
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const responseBody = await response.json();
      console.log('Server Response Body:', responseBody);

      const { analyzedWorkout } = responseBody;
      if (analyzedWorkout === undefined) {
        console.error('Server did not return the expected data structure.');
      } else {
        console.log('Analyzed Workout:', analyzedWorkout);
      }

      return analyzedWorkout;

    } catch (error) {
      console.error('Error:', error.message);
      // Handle errors appropriately
    }
  }


  // HELPER FUNCTIONS
  async function handleSubmit(event) {
    event.preventDefault();
    const workoutHistoryCopy = JSON.parse(JSON.stringify(userWorkoutHistory));
    // Create a new array with the updated workout history
    const updatedWorkoutHistory = [
      ...workoutHistoryCopy.userWorkoutHistory,
      {
        timestamp: Date.now(),
        programID: program.programID,
        programName: program.programName,
        workoutID: workout.workoutID,
        workoutName: workout.workoutName,
        workoutScheduleIndex: currentWorkoutScheduleIndex,
        workoutExercises: workout.workoutExercises
      }
    ];
  
    // Create a new object with the updated workout history array
    const updatedUserWorkoutHistory = {
      ...workoutHistoryCopy,
      userWorkoutHistory: updatedWorkoutHistory
    };
    try {
      // Update app global state and local storage
      // await updateUserWorkoutHistory(updatedUserWorkoutHistory);
      // Update Workout History in Database and local storage
      await updateDbWorkouts(updatedUserWorkoutHistory);
      alert("Workout Logged");
      history.push("/dashboard");
    } catch (error) {
      // Handle errors here
      alert("Error updating workout: " + error);
      // You can show an error message to the user or take other appropriate actions
    }
  }
  

  function displayExercises() {
    console.log(workout);
    // If no workouts in history, fill in workout components with base program values
    // check if there is any logic for this specific workout
    // If condition sets conflict, last one will be used automatically in program logic.
    // match to workoutid of selected workout on dashboard
    /* Creates the sets and fills in deets (reps,weight,...) for the workout from last workout (if no logic)
    */
    if (!workout || !workout.workoutExercises || workout.workoutExercises.length === 0) {
      return "Nothing to Show";
    }
    return workout.workoutExercises.map((exercise, index) => {
      let Component;
      if (exercise.typeSelected === "resistance") {
        Component = ResistanceExercise;
      } else if (exercise.typeSelected === "circuit") {
        Component = CircuitExercise;
      }

      return (
        <Component
          key={exercise.exerciseName}
          specificExercise={exercise}
          fullWorkout={workout}
          updateWorkout={setWorkout}
        />
      );
    });
  }

  return (
    <Container className="text-center">
      <Form onSubmit={(event) => handleSubmit(event)}>
        <h1 className="mb-4">Workout: {workout ? workout.workoutName: "Bugz"}</h1>
        {workout ? displayExercises() : "No Exercises in this workout. Go add some in the Program Editor!"}
        <button type="submit" className="dashboard-btn">Finish</button>
      </Form>
    </Container>
  );

}

export default Workout;

