import React, { Fragment } from 'react';

const ExerciseList = ({ exercises, clickHandler }) => {
  // Sort exercises alphabetically
  const sortedExercises = exercises.sort((a, b) => a.exerciseName.localeCompare(b.exerciseName));

  // Group exercises by their starting letter
  const groupedExercises = sortedExercises.reduce((acc, exercise) => {
    const firstLetter = exercise.exerciseName[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(exercise);
    return acc;
  }, {});

  return (
    <div className="exercise-list">
      {Object.keys(groupedExercises).map(letter => (
        <Fragment key={letter}>
          <h3 style={{ fontWeight: 'bold' }}>{letter}</h3>
          <ul className="list-group">
            {groupedExercises[letter].map(exercise => (
              <li key={exercise.exerciseID} className="list-group-item" onClick={() => clickHandler(exercise)} style={{ color: "#A10404" }}>
                <a href="#" style={{ textDecoration: "none"}}>
                  {exercise.exerciseName}
                </a>
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </div>
  );
};

export default ExerciseList;