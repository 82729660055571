import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-8MY1Z1E343';
    script.async = true;

    const scriptTag = document.createElement('script');
    scriptTag.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-8MY1Z1E343');
    `;

    document.head.appendChild(script);
    document.head.appendChild(scriptTag);
  }, []);

  return null; // This component doesn't render anything
};

export default GoogleAnalytics;
