import React, { useState, useCallback } from "react";
import "./workoutSchedule.css";

const OrderableList= ({ list, updateList, onItemClick }) => {
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  console.log(list);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString());
    setDraggedIndex(index);
    const item = e.target;
    setTimeout(() => {
      item.classList.add("dragging");
    }, 0);
  };

  const handleDragOver = useCallback((e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  }, []);

  const handleDrop = (e, destinationIndex) => {
    e.stopPropagation();
    const sourceIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);
    const newList = [...list];
    const [removed] = newList.splice(sourceIndex, 1);
    newList.splice(destinationIndex, 0, removed);
    updateList(newList);
    setDraggedIndex(null);
    setDraggedOverIndex(null);
  };

  const handleDragEnd = (e) => {
    const item = e.target;
    item.classList.remove("dragging");
    setDraggedIndex(null);
  };

  const handleDelete = (index) => {
    const newList = [...list];
    const exerciseID = newList[index].exerciseID;
    newList.splice(index, 1);
    updateList(newList,exerciseID);
  };

  return (
    <div
      id="target"
      onDragOver={list.length === 0 ? (e) => handleDragOver(e, 0) : null}
      onDrop={list.length === 0 ? (e) => handleDrop(e, 0) : null}
      style={{
        width: "600px",
        margin: "0 auto",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        background:
          draggedOverIndex === 0 && list.length === 0 ? "#A10404" : "white",
      }}
    >
      <ol start={1}>
        {list.length > 0 &&
          list.map((item, index) => {
            const isDragged = index === draggedIndex;
            const isDraggedOver = index === draggedOverIndex;
            const itemStyles = {
              transform: isDraggedOver ? "translateY(10px)" : "none",
            };
            return (
              <li
                key={index}
                className="item"
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={(e) => handleDrop(e, index)}
                onDragEnd={handleDragEnd}
                onClick={()=> onItemClick(index)}
                style={itemStyles}
              >
                <div className="details">
                  <span className="number">{index + 1}.</span>
                  <span className="item-name">{item.exerciseName}</span>
                  <i
                    className="fa fa-trash"
                    aria-hidden="true"
                    onClick={() => handleDelete(index)}
                  ></i>
                </div>
              </li>
            );
          })}
      </ol>
    </div>
  );
};

export default OrderableList;
