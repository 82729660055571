// TAKES IN ALL IDS FROM ALL EXERCISES AND CHANGES EACH SPOT LIKE DIAGONAILIZATION PROOF

// ExerciseID,
export function generateUniqueExerciseID(idType, allIDs) {
  // generate random char string and see if it exists
  var newID = [];
  // this creates character array of all single digit numbers,letters, and special characters. 76 chars
  var allowedChars = [
    ..."0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()-_=+"
  ];
  var h = 0;
  var i = 0;
  var id = "";
  switch (idType) {
    case "exercise":
      i = 11;
      id = "exerciseID";
      break;
    case "workout":
      i = 12;
      id = "workoutID";
      break;
    case "program":
      i = 13;
      id = "programID";
      break;
  }
  while (h < i) {
    let newChar = allowedChars[Math.floor(Math.random() * 75)];
    newID.push(newChar);
    h++;
  }
  // search all current ids in specific object
  if (allIDs.find(({ id }) => id === newID.join("")) === undefined) {
    return newID.join("");
  }
  else {
    // run it again if it generated a previous id.
    generateUniqueExerciseID(idType, allIDs);
  }
}