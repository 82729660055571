import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';

export default function ResistanceFormEditor(props) {
  console.log("In resistance form editor");
  console.log(props.selectedExercise);
  /*** Structure of exercise
   *          						{
							"exerciseName": "Back Squat",
							"exerciseID": "2dLun3f3s54a",
							"typeSelected": "resistance",
							"sets": [
								{
									"reps": 0,
									"weight": 0,
									"duration": 0,
									"rest": 0,
									"set-type": "standard"
								}
							],
							"notes": "Some Notes"
						}
   * 
   * 
   */

  // This components state
  // will be object with {name:value} for each select field on here.
  const [allSelectFieldsVals, updateAllSelectFieldVals] = useState("");
  /* *************************ALL HELPER FUNCTIONS ************************************/
  function handleInputChange(index, event) {
    console.log(index.target);
    console.log(event);
    let programCopy = { ...props.programFields };
    let exerciseIndex = programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises.findIndex(({ exerciseID }) => exerciseID === props.selectedExercise.exerciseID);
    programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises[exerciseIndex].sets[index][event.target.name] = event.target.value;
    props.updateProgramFields(programCopy);
  }

  // TO ADD OR REMOVE SETS
  const handleAddFields = () => {
    let programCopy = { ...props.programFields };
    let exerciseIndex = programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises.findIndex(({ exerciseID }) => exerciseID === props.selectedExercise.exerciseID);
    //shallow copy
    const values = [...props.selectedExercise.sets];
    values.push({ reps: 0, weight: 0, duration: 0, rest: 0, "set-type": "standard" });
    // update just the sets with new array
    programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises[exerciseIndex].sets = values;
    props.updateProgramFields(programCopy);
  };

  const handleRemoveFields = (index) => {
    //shallow copy
    const values = [...props.selectedExercise.sets];
    if (values.length === 1) {
      alert("There must be at least one set!");
      return;
    }
    values.splice(index, 1);
    // update just the sets with new array
    let programCopy = { ...props.programFields };
    let exerciseIndex = programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises.findIndex(({ exerciseID }) => exerciseID === props.selectedExercise.exerciseID);
    // update just the sets with new array
    programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises[exerciseIndex].sets = values;
    props.updateProgramFields(programCopy);
  };

  function handleSelectChange(event, index) {
    // set value of select to option selected
    updateAllSelectFieldVals(event.target.value);
    // update local state
    handleInputChange(index, event);
  }

  /* ************************  RETURN THE COMPONENT *****************************/

  return (
    <div className="container">
      <div className="form-row-duub">
        <h1>Sets Editor</h1>
        <div id="resp-table">
          <div id="resp-table-header">
            <div className="table-header-cell">Reps</div>
            <div className="table-header-cell">Weight</div>
            <div className="table-header-cell">Duration</div>
            <div className="table-header-cell">Rest</div>
            <div className="table-header-cell">Set Type</div>
          </div>
          {props.selectedExercise.sets
            ? props.selectedExercise.sets.map((set, index) => (
              <Fragment key={`${set}~${index}`}>
                <div id="resp-table-body">
                  <div className="resp-table-row">
                    <div className="table-body-cell">
                      <input
                        id="reps"
                        type="number"
                        min="0"
                        value={set.reps}
                        name="reps"
                        onChange={(event) =>
                          handleInputChange(index, event)
                        }
                      />
                    </div>
                    <div className="table-body-cell">
                      <input
                        id="weight"
                        type="number"
                        min="0"
                        value={set.weight}
                        name="weight"
                        onChange={(event) =>
                          handleInputChange(index, event)
                        }
                      />
                    </div>
                    <div className="table-body-cell">
                      <input
                        id="duration"
                        type="number"
                        min="0"
                        value={set.duration}
                        name="duration"
                        onChange={(event) =>
                          handleInputChange(index, event)
                        }
                      />
                    </div>
                    <div className="table-body-cell">
                      <input
                        id="rest"
                        type="number"
                        min="0"
                        value={set.rest}
                        name="rest"
                        onChange={(event) =>
                          handleInputChange(index, event)
                        }
                      />
                    </div>
                    <div className="table-body-cell">
                      <select id={index}
                        name="set-type"
                        value={props.selectedExercise.sets[index] ?
                          props.selectedExercise.sets[index]["set-type"] : allSelectFieldsVals.setType}
                        onChange={(event) => handleSelectChange(event, index)}>
                         <option value="warmup">Warmup</option>
                        <option value="standard">Standard</option>
                        <option value="amrap">AMRAP</option>
                        <option value="pyramid">Pyramid</option>
                        <option value="dropset">Dropset</option>
                      </select>
                    </div>
                    <div className="table-body-cell">
                      <div className="vertical-center">
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() => handleRemoveFields(index)}
                        >
                          (-)
                        </button>
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() => handleAddFields()}>
                          (+)
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))
            : "No Exercise"}
        </div>
      </div>
    </div>
  );
}
