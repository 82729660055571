import React, { useState, Fragment,useEffect, useContext } from "react";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';

export default function CircuitEditorForm(props) {
  const {
    userExercises,
    ttExercises,
    isAuthenticated,
  } = useContext(AppStateContext);

const [history] = useState(props.history);
// Don't allow non-logged in users to access any pages other than login
if (!isAuthenticated) {
  // redirect to login
  history.push("/");
}

  // This components state
  // This is an object with the following structure
  /* 
 {
          "exerciseName": "Circuit",
          "exerciseID": "a6456sd645f4fg47",
          "typeSelected": "circuit",
          "rounds": 10,
          "notes": "Friggn' hard bruh",
          "exercisesInCircuit": [
            {
              "exerciseName": "Jabs",
              "exerciseID": "f34sf63487fs",
              "sets": [
                {
                  "reps": 100,
                  "weight": 8,
                  "duration": 60,
                  "rest": 30,
                  "set-type": "circuit"
                }
              ]
            },
            {
              "exerciseName": "Hooks",
              "exerciseID": "zz4sasdfferyjn67hfgj",
              "sets": [
                {
                  "reps": 100,
                  "weight": 8,
                  "duration": 60,
                  "rest": 30,
                  "set-type": "circuit"
                }
              ]
            }
          ]
        }
      */

  const [circuitSearchTerm, setCircuitSearchTerm] = useState('');
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [selectExercise, setSelectExercise] = useState(null);

  const [exerciseEditor, showExerciseEditor] = useState(false);

  // STORES AN ARRAY INDEX VALUE
  const [selectedExercise, setSelectedExercise] = useState(0);

  // changes button for add exercise
  const [addRemoveBtn,changeAddRemoveBtn] = useState("(+)");

  /* *************************ALL HELPER FUNCTIONS ************************************/
  function handleInputChange(event,index) {
    // create copy of program obj
    let programCopy = { ...props.programFields };
    let exerciseIndex = programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises.findIndex(({ exerciseID }) => exerciseID === props.selectedExercise.exerciseID);
    if (event.target.name=== "rounds"){
      programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises[exerciseIndex].rounds = event.target.value;
    }
    else if(event.target.name=== "circuitName"){
      programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises[exerciseIndex].exerciseName = event.target.value;
    }
    else{
      programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises[exerciseIndex].exercisesInCircuit[event.target.id].sets[0][event.target.name] = event.target.value;
    }
    props.updateProgramFields(programCopy);
  }

  const handleRemoveFields = (index) => {
    //shallow copy
    const values = [...props.selectedExercise.exercisesInCircuit];
    if(values.length===1){
      alert("Circuit must contain at least 1 exercise!");
      return;
    }
    values.splice(index, 1);
    // create copy of program obj
    let programCopy = { ...props.programFields };
    let exerciseIndex = programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises.findIndex(({ exerciseID }) => exerciseID === props.selectedExercise.exerciseID);
    // update just the sets with new array
    programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises[exerciseIndex].exercisesInCircuit = values;
    props.updateProgramFields(programCopy);
  };

    // Search for an exercise to add to the workout ----------------------------
    const handleSearch = (event) => {
      setSelectExercise(null);
      const circuitSearchTerm = event.target.value;
      setCircuitSearchTerm(circuitSearchTerm);
    
      if (circuitSearchTerm.trim().toLowerCase() === '') {
        setFilteredExercises([]);
        return;
      }
      const combinedExercises = [...ttExercises.TemplateTrainerExercises, ...userExercises.userExercises];
      const filteredExercises = combinedExercises.filter(exercise => {
        const keysToSearch = ["musclesInvolved", "equipment", "exerciseName"];
        for (let key in exercise) {
          if (keysToSearch.includes(key)) {
            if (
              typeof exercise[key] === 'string' &&
              exercise[key].toLowerCase().includes(circuitSearchTerm.toLowerCase())
            ) {
              return true;
            }
            if (
              Array.isArray(exercise[key]) &&
              exercise[key].some(value => typeof value === 'string' && value.toLowerCase().includes(circuitSearchTerm.toLowerCase()))
            ) {
              return true;
            }
          }
        }
        return false;
      });
      
      setFilteredExercises(filteredExercises);
    };
  
    const handleExerciseClick = (event,exercise) => {
      event.preventDefault();
      setSelectExercise(exercise);
      setCircuitSearchTerm(exercise.exerciseName);
      setFilteredExercises([]);
      // add the exercise with proper structure
      let exerciseStructure = {
          exerciseName: exercise.exerciseName,
          exerciseID: exercise.exerciseID,
          sets: [
            { reps: 0, weight: 0, duration: 60, rest: 30, "set-type": "circuit" }
          ]
        };
        // create copy of program obj
        let programCopy = { ...props.programFields };
        let exerciseIndex = programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises.findIndex(({ exerciseID }) => exerciseID === props.selectedExercise.exerciseID);
        // update just the sets with new array
        programCopy.workoutsInProgram[props.currentWorkoutIndex].workoutExercises[exerciseIndex].exercisesInCircuit.push(exerciseStructure);
        props.updateProgramFields(programCopy);
    };

  /* ************************  RETURN THE COMPONENT *****************************/

  return (
    <div id="container">
      <div id="circuitNameInput">
          <input
            type="text"
            required
            className="circuitName form-control"
            id="circuitName"
            name="circuitName"
            value={props.selectedExercise?props.selectedExercise.exerciseName:"No Name"}
            onChange={(event) => handleInputChange(event)}
          />
      </div>
      <hr />
      <h3> Exercises In Circuit </h3>
      <label htmlFor="rounds"> Rounds </label>
      <input
        id="rounds"
        name="rounds"
        type="number"
        value={props.selectedExercise.rounds}
        min="0"
        onChange={(event) => handleInputChange(event)}
      />
      <div className="form-row-duub">
        <div id="resp-table">
          <div id="resp-table-header">
            <div className="table-header-cell">Exercise</div>
            <div className="table-header-cell">Reps</div>
            <div className="table-header-cell">Weight</div>
            <div className="table-header-cell">Duration</div>
            <div className="table-header-cell">Rest</div>
          </div>
          {props.selectedExercise.exercisesInCircuit
            ? props.selectedExercise.exercisesInCircuit.map((exercise, index) => (
                <Fragment key={`${exercise.exerciseName}~${index}`}>
                  <div id="resp-table-body">
                    <div className="resp-table-row">
                      <div className="table-body-cell">
                        {exercise.exerciseName}
                      </div>
                      <div className="table-body-cell">
                        <input
                          id= {index}
                          type="number"
                          min="0"
                          value={exercise.sets[0].reps}
                          name="reps"
                          onChange={(event,index) => handleInputChange(event,index)}
                        />
                      </div>
                      <div className="table-body-cell">
                        <input
                          id={index}
                          type="number"
                          min="0"
                          value={exercise.sets[0].weight}
                          name="weight"
                          onChange={(event,index) => handleInputChange(event,index)}
                        />
                      </div>
                      <div className="table-body-cell">
                        <input
                          id={index}
                          type="number"
                          min="0"
                          value={exercise.sets[0].duration}
                          name="duration"
                          onChange={(event,index) => handleInputChange(event,index)}
                        />
                      </div>
                      <div className="table-body-cell">
                        <input
                          id={index}
                          type="number"
                          min="0"
                          value={exercise.sets[0].rest}
                          name="rest"
                          onChange={(event,index) => handleInputChange(event,index)}
                        />
                      </div>
                      <div className="table-body-cell">
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() => handleRemoveFields(index)}
                        >
                          (-)
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ))
            : "No Exercise"}
        </div>
      </div>
      <div>
      <label>
        Add Exercise to Circuit
      </label>
      <div>
      <button
         className="btn btn-link"
         type="button" 
         onClick={()=> exerciseEditor ? showExerciseEditor(false)
          :showExerciseEditor(true)}>
          {addRemoveBtn}
         </button>
         </div>
      </div>
      <div id="add-new-exercise" style={{display: exerciseEditor ? "block":"none"}}>
        <div className="form-group col-sm-6">
          <hr/>
          <label>Search for Exercises </label>
          <input
            type="text"
            placeholder="Search exercises"
            value={circuitSearchTerm}
            onChange={handleSearch}
          />
          <ul className="list-group">
            {filteredExercises.map(exercise => (
              <li key={exercise.exerciseID} className="list-group-item list-group-item-action" onClick={(event) => handleExerciseClick(event,exercise)}>
              <a href="#" style={{ textDecoration: "none", color:"#A10404" }}> {exercise.exerciseName}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
