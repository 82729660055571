import React, { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {
  faPlayCircle,
  faPauseCircle,
  faSyncAlt
} from "@fortawesome/free-solid-svg-icons";
import "./CircuitTimer.css"; // Import CSS file for styling
//Trying to fix audio stopping on page losing focus and timing out for long circuits
import { useSound } from 'use-sound';
import FinishedSound from "../assets/audio/Finished.mp3";
import CountdownSound from "../assets/audio/ThreeTwoOne.mp3";
import GetItSound from "../assets/audio/Go.mp3";
import RestSound from "../assets/audio/Rest.mp3";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';

export default function CircuitTimer(props) {
  // TODO: Access the app state for countdown sounds in user profile
  const {user} = useContext(AppStateContext);
  const [active, setActive] = useState(true);
  const [time, setTime] = useState(0);
  const [start, setStart] = useState(0);
  const [activeExercise, setActiveExercise] = useState(0);
  const [rounds, setRounds] = useState(props.specificExercise.rounds);
  const [paused, setPaused] = useState(true);
  const [initialRounds, setInitialRounds] = useState(props.specificExercise.rounds); 
  
  // Sounds
  const [playFinished, { stopFinished}] = useSound(FinishedSound);
  const [playCountdown, { stopCountdown}] = useSound(CountdownSound);
  // needed to stop multiple countdowns
  const [countingDown,setCountingDown] = useState(false);
  const [playGetIt, { stopGetIt}] = useSound(GetItSound);
  const [playRest, { stopRest}] = useSound(RestSound);
  // Store initial Rounds for reset button
  useEffect(() => {
    // Set the initial value of rounds when props change
    setInitialRounds(props.specificExercise.rounds);
  }, [props.specificExercise]);
  
  useEffect(() => {
    let timer = setTimeout(() => {
      let timeElapsed = start === 0 ? 0 : (Date.now() - start) / 1000;
      console.log(timeElapsed);
      // If there are still rounds left
      if (rounds >= 1) {
        if (!paused) {
          if (active) {
            if (
              props.specificExercise.exercisesInCircuit[activeExercise].sets[0]
                .duration - timeElapsed > 0.5
            ) {
              setTime(
                props.specificExercise.exercisesInCircuit[activeExercise]
                  .sets[0].duration - timeElapsed
              );              
              // Check for countdown sound effects
              const countdownSeconds = Math.floor(
                props.specificExercise.exercisesInCircuit[activeExercise].sets[0]
                  .duration - timeElapsed
              );

              if (countdownSeconds === 3 && !countingDown) {
                setCountingDown((prevCountingDown) => {
                  if (!prevCountingDown) {
                    playCountdown();
                  }
                  return true;
                });
              }

            } else {
              setTime(0);
              setStart(Date.now());
              setActive(false);
              //play rest sound
              playRest();
              setCountingDown(false);
            }
          } else {
            if (
              props.specificExercise.exercisesInCircuit[activeExercise].sets[0]
                .rest - timeElapsed > 0.5
            ) {
              setTime(
                props.specificExercise.exercisesInCircuit[activeExercise]
                  .sets[0].rest - timeElapsed
              );              
              // Check for countdown sound effects
              const countdownSeconds = Math.floor(
                props.specificExercise.exercisesInCircuit[activeExercise].sets[0]
                  .rest - timeElapsed
              );
              if (countdownSeconds === 3 && !countingDown) {
                setCountingDown((prevCountingDown) => {
                  if (!prevCountingDown) {
                    playCountdown();
                  }
                  return true;
                });
              }
            } else {
              // Check if last active exercise
              if(activeExercise + 1  ===
                props.specificExercise.exercisesInCircuit.length){
                  setTime(0);
                  setStart(Date.now());
                  setActive(true);
                  setRounds(rounds - 1);
                  setActiveExercise(0);
                  if(rounds-1 !== 0){
                    //Play Get It!
                    playGetIt();
                    setCountingDown(false);
                  }
                }else{
                  setTime(0);
                  setStart(Date.now());
                  setActive(true);
                  setActiveExercise(activeExercise + 1);
                  //Play Get It!
                  playGetIt();
                  setCountingDown(false);
                }
            }
          }
        }
      } else {
        //play workout Finished sound
        playFinished();
        setCountingDown(false);
      }
    }, 120);

    return () => {
      clearTimeout(timer);
      console.log("Timeout Cleared");
    };
  }, [time, start, rounds, activeExercise, paused]);

  useEffect(() => {
    // Calculate the progress percentage for the circular progress bar
    const progressPercentage = active ?
      (time /
        props.specificExercise.exercisesInCircuit[activeExercise].sets[0]
          .duration) *
      100 : (time /
      props.specificExercise.exercisesInCircuit[activeExercise].sets[0]
        .rest) *
    100;
    const radius = 52; // Radius of the circle
    const circumference = 2 * Math.PI * radius; // Calculate the circumference

    // Calculate the strokeDasharray and strokeDashoffset based on the progress percentage
    const strokeDasharray = circumference;
    const strokeDashoffset =
      circumference - (progressPercentage / 100) * circumference;

    setProgressData({ progressPercentage, radius, circumference, strokeDasharray, strokeDashoffset });
  }, [time, activeExercise,rounds]);

  const [progressData, setProgressData] = useState({
    progressPercentage: 0,
    radius: 52,
    circumference: 2 * Math.PI * 52,
    strokeDasharray: 2 * Math.PI * 52,
    strokeDashoffset: 0
  });

  function formatTime() {
    let status = "";
    if (rounds >= 1) {
      status = active ? "GET IT! " : "REST ";
    }else{
      status = "Finished! \n";
    }
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = Math.floor(time % 60);

    let displayHours = hours < 10 ? "0" + hours : hours;
    let displayMinutes = minutes < 10 ? "0" + minutes : minutes;
    let displaySeconds = seconds < 10 ? "0" + seconds : seconds;

    return status + displayHours + ":" + displayMinutes + ":" + displaySeconds;
  }

    // Reset the timer to the initial state
    const resetTimer = (e) => {
      if(e){
        e.preventDefault();
      }
      playFinished(); // Invoke the play function
      setStart(0);
      setTime(0);
      setActiveExercise(0);
      setRounds(props.specificExercise.rounds);
      setPaused(true);
      setActive(true);
    };
    
  return (
    <div className="circuit-timer">
      <div className="exercise-name">
        {props.specificExercise.exercisesInCircuit[activeExercise].exerciseName}
      </div>
      <div className="timer-display">
        <svg className="progress-ring" width="120" height="120">
          <circle
            className="progress-ring__circle"
            strokeWidth="8"
            fill="transparent"
            r={progressData.radius}
            cx="60"
            cy="60"
          />
          <circle
            className={`progress-ring__progress ${active ? "active" : "rest"}`}
            strokeWidth="8"
            fill="transparent"
            r={progressData.radius}
            cx="60"
            cy="60"
            style={{
              strokeDasharray: `${progressData.strokeDasharray}`,
              strokeDashoffset: `${progressData.strokeDashoffset}`,
            }}
          />
        </svg>
        <div className="timer-text">{formatTime()}</div>
      </div>
      <div className="timer-controls">
        <button
          className="timer-button"
          onClick={(e) => {
            e.preventDefault();
            if (paused) {
              setStart(Date.now() - start);
              console.log(start);
              setPaused(false);
            } else {
                setStart(Date.now() - start);
                setPaused(true);
            }
          }}

        >
          {paused ? (
            <FontAwesomeIcon icon={faPlayCircle} />
          ) : (
            <FontAwesomeIcon icon={faPauseCircle} />
          )}
        </button>
        <button
  className="timer-button"
  onClick={(e) => {resetTimer(e)}}
>
  <FontAwesomeIcon icon={faSyncAlt} />
</button>

      </div>
      <div className="rounds">
        <h1>{rounds} Rounds</h1>
      </div>
    </div>
  );
}
