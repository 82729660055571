import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      // check if this was called from field input which will have key=target
      if("target" in event){
        setValues({
          ...fields,
          [event.target.id]: event.target.value
        });
      }
      else{
        // pass in new field data
        setValues(Object.assign(
          {...fields},event));
      }
    }
  ];
}