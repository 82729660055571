import React, { Fragment, useState,useContext,useEffect } from "react";
// MY CUSTOM FUNCTION
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
import "bootstrap/dist/css/bootstrap.css";
  // PASSWORD STUFF
  // https://www.npmjs.com/package/react-native-bcrypt
import bcrypt from "bcryptjs";
// Updates Database when Network available, Queue when offline. Updates Context API
import { useOfflineFetch } from '../libs/offlineFetch';


function UserSettings(props) {
  // Globale State from REACT CONTEXT API
  // Access the app state and update functions using useContext
  const {
    user,
    isAuthenticated,
    jwt,
    updateUser,
    updateAuth,
    clearAppState
  } = useContext(AppStateContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const domain = "https://api.templatetrainer.com";

   // history is passed in by react router. keeps record of pages visited
 const [history] = useState(props.history);
 // Don't allow non-logged in users to access any pages other than login
 if (!isAuthenticated) {
   // redirect to login
   history.push("/");
 }
  // look into timezone api to get UTC value or something like https://everytimezone.com/
  // set timezone options
  let timezonearray = [
    "PST",
    "MST",
    "CST",
    "EST",
    "GMT",
    "UTC",
    "CET",
    "MSK",
    "IST",
    "JST",
    "AEDT",
    "NZDT"
  ];

  // CREATE ALL FORM FIELDS
  let timezoneoptions = [];
  for (let code in timezonearray) {
    let option = (
      <option key={code} value={timezonearray[code]}>
        {timezonearray[code]}
      </option>
    );
    timezoneoptions.push(option);
  }

  //set weight options
  let weightarray = ["LB", "KG", "Stone"];
  let weightoptions = [];
  for (let unit in weightarray) {
    let option = (
      <option key={unit} value={weightarray[unit]}>
        {weightarray[unit]}
      </option>
    );
    weightoptions.push(option);
  }

  //set distance options
  let distancearray = ["meter", "foot"];
  let distanceoptions = [];
  for (let unit in distancearray) {
    let option = (
      <option key={unit} value={distancearray[unit]}>
        {distancearray[unit]}
      </option>
    );
    distanceoptions.push(option);
  }

  let accountArray = ["Athlete", "Coach"];
  let accountOptions = [];
  for (let type in accountArray) {
    let option = (
      <option key={type} value={accountArray[type]}>
        {accountArray[type]}
      </option>
    );
    accountOptions.push(option);
  }

  // Helper functions

  //update state. THIS IS NOT SAFE YET - need to validate input.
  function handleChange(event) {
    let key = event.target.id;
    let value = event.target.value;
    // for Profile IMG see: https://www.geeksforgeeks.org/upload-and-retrieve-image-on-mongodb-using-mongoose/
    const updatedUserSettings = {
      ...user,
      [key]: value
    };
    // Context API
    updateUser(updatedUserSettings);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let updatedUserSettings = { ...user };
    if (oldPassword !== newPassword && oldPassword !== "" && newPassword !== "") {
      const isPasswordValid = await bcrypt.compare(oldPassword, user.password);
      if (!isPasswordValid) {
        return alert("Invalid Current password");
      }
      try {
        const salt = await bcrypt.genSalt(10);
        const hash = await bcrypt.hash(newPassword, salt);
        updatedUserSettings = {
          ...updatedUserSettings,
          password: hash,
        };
      } catch (error) {
        console.error(error);
        return;
      }
    }
    const newUserData = await updateDbUser(updatedUserSettings);
    // updateUser(updatedUserSettings);
    alert("User Updated");
    console.log(newUserData);
  }

  // Updates the Workout History in DB and Workout History in React Context Api
  const offlineFetch = useOfflineFetch();

 const  updateDbUser = async (userData) => {
  try {
    const url = domain+"/updateUser";
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwt}`,
      },
      credentials: 'include', // Set this to true to include cookies in the request
      body: JSON.stringify(userData),
    };
    const response = await offlineFetch(url, options,'user',userData);
    const updatedUser = await response.json();
    // Process the user data or perform actions based on the response
    return updatedUser;

  } catch (error) {
    console.log(error);
    // Handle error scenario, display an error message, etc.
    throw error;
  }
};

// Delete the user account and all programs,exercises, and workout history.
// Only perform if Network available. Dont use offlineFetch.
const deleteUser = async (userID) => {
  console.log(userID);

  let response = window.confirm("Delete This Account? This cannot be undone!");
  if (response) {
    const token = document.cookie.split('; ').find((row) => row.startsWith('token='));
    const authToken = token ? token.split('=')[1] : null;
  try {
    const response = await fetch(domain+"/deleteUser", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: 'include', // Set this to true to include cookies in the request
      body: JSON.stringify({ id: userID }),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.error || "User deletion failed";
      throw new Error(errorMessage);
    }

    const userData = await response.json();
    // Process the user data or perform actions based on the response
    updateAuth(false);
    clearAppState();
    return userData;
  } catch (error) {
    console.log("Error in deleteUser function:", error);
    // Handle error scenario, display an error message, etc.
    throw error;
  }
}
};


  return (
    <div className="userProfileContainer"
    style={{ margin: '20px auto', padding: '20px', maxWidth: '800px' }}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <h1 className="text-center"> User Details </h1>
          <div className="row">
            <div className="col">
              <label>
                Name:
                </label>
                <input
                  className="form-control"
                  id="name"
                  type="text"
                  value={user.name}
                  onChange={(event) => handleChange(event)}
                />
                </div>
            <div className="col">
              <label>
                Age:
              </label>
              <input
                  className="form-control"
                  id="age"
                  type="number"
                  min="0"
                  value={user.age}
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div className="col">
              <label>
                Height:
                </label>
                <input
                  className="form-control"
                  id="height"
                  type="number"
                  min="0"
                  value={user.height}
                  onChange={(event) => handleChange(event)}
                />
              </div>
            </div>
            <div className="row">
            <div className="col">
          <label>
            Email:
            </label>
            <input
              className="form-control"
              id="email"
              type="text"
              value={user.email}
              onChange={(event) => handleChange(event)}
            />
            </div>
            <div className="col">
          <label>
            Account Type
          </label>
          <select 
          id="accountType"
          value={user.accountType}
          onChange={(event) => handleChange(event)}
            >
              {accountOptions}
          </select>
          </div>
          </div>
          <h1 className="text-center"> Global Settings</h1>
          <div className="row">
            <div className="col">
          <label>
            Weight Units:
            </label>
            <select
              id="weightUnits"
              value={user.weightUnits}
              onChange={(event) => handleChange(event)}
            >
              {weightoptions}
            </select>
            </div>
            <div className="col">
          <label>
            Your Timezone:
            <select
              id="timezone"
              value={user.timezone}
              onChange={(event) => handleChange(event)}
            >
              {timezoneoptions}
            </select>
          </label>
          </div>
          <div className="col">
          <label>
            Distance Units:
            <select
              id="distanceUnits"
              value={user.distanceUnits}
              onChange={(event) => handleChange(event)}
            >
              {distanceoptions}
            </select>
          </label>
          </div>
        </div>
        <h1 className="text-center"> Change Password</h1>
        <div className="row">
        <div className="col">
          <label>
            Current Password:
            <input
              id="password"
              type="text"
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
            />
          </label>
          </div>
          <div className="col">
          <label>
            New Password:
            <input
              id="password"
              type="text"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </label>
          </div>
          </div>
        <input type="submit" value="Submit" />
        <button className="dashboard-btn" onClick={() => user["_id"] && deleteUser(user["_id"])}>
  Delete Account
</button>

      </form>
    </div>
  );
}

export default UserSettings;
