import React, { Fragment, useState, useContext } from "react";
import LOGO from "./template-trainer-logo-1.gif";
import "bootstrap/dist/css/bootstrap.css";
import "./LoginStyling.css"
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
// MY CUSTOM HOOK FOR ALL FORMS
import { useFormFields } from "../libs/hooksLib";
import LoadingModal from './LoadingModal'; 

//First page seen by visitors

// Added a registration code field for signups for the pre-release.

/* IF LOGGING IN: 
* 1) CHECKS DATABASE FOR USER EMAIL AND PASSWORD IN SAME OBJECT
* 2) IF USER EXISTS, GRABS PASSWORD AND CHECKS FOR MATCH AGAINST WHAT USER TYPED
* 3) REDIRECTS TO USER DASHBOARD WITH ALL USER ASSETS STORED IN REACT CONTEXT API
*/

function Login(props) {
  // Globale State from REACT CONTEXT API
  // Access the app state and update functions using useContext
  const {
    user,
    userPrograms,
    userExercises,
    ttExercises,
    userWorkoutHistory,
    isAuthenticated,
    jwt,
    pendingRequests,
    updateUser,
    updateUserPrograms,
    updateUserExercises,
    updateTTExercises,
    updateUserWorkoutHistory,
    updateAuth,
    updateJWT,
    updatePendingRequests,
    clearAppState
  } = useContext(AppStateContext);

  // Local component state
  const [fields, handleFieldChange] = useFormFields({
    "loginEmail": "",
    "loginPass": "",
    "signupName": "",
    "signupEmail": "",
    "signupPass": "",
    "signupPassCheck": "",
    "registrationCode":""
  });
  // local component state
  const [signup, showSignup] = useState(false);
  const [signupButtonDisabled, setSignupButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [login, showLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // SINCE WE ARE USING REACT ROUTER, WE HAVE HISTORY AVAILABLE
  const [history] = useState(props.history);
  const domain = "https://api.templatetrainer.com";

  // LOGIN FORM STUFF
  function showLoginForm() {
    if (login) {
      showLogin(false);
    } else {
      showLogin(true);
      showSignup(false);
    }
  }

  // show/hide the password on login form
  function showThePassword() {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }

  function validateLoginForm() {
    return fields.loginEmail.length > 0 &&
      fields.loginPass.length > 0;
  }

  async function handleLoginSubmit(event) {
    event.preventDefault();
    const loginData = {
      email: fields.loginEmail,
      password: fields.loginPass
    };
    // CHECK IF USER EXISTS. IF NOT, SAY CREATE ACCOUNT.
    // Compare password in server side code
    try {
      setLoading(true);
      const userData = await userSearch(loginData);
        // Handle successful login
        // update all user objects state in React Context api
        // If an object is missing in database, create it for the user.
        alert("You are logged in!");
        console.log(userData);
        updateAuth(true);
        updateJWT(userData.token || null);
        updateUser(userData.user);
        updateUserPrograms(userData.programs ? userData.programs : {"_id":userData.user._id,userPrograms:[]});
        updateUserWorkoutHistory(userData.workouts ? userData.workouts : {"_id":userData.user._id,userWorkoutHistory:[]});
        updateUserExercises(userData.exercises ? userData.exercises : {"_id":userData.user._id,userExercises:[]});
        updateTTExercises(userData.ttExercises ? userData.ttExercises : null);
        updatePendingRequests(userData.pendingRequests?userData.pendingRequests:null);
        setLoading(false);
        // redirect to dashboard
        history.push("/dashboard");
    } catch (error) {
      // Handle error scenario, display an error message, etc.
      console.error("Error logging in:", error.message);
      alert("Error logging in: " + error.message);
      //clear inputs
      handleFieldChange({
        "loginEmail": "",
        "loginPass": "",
      })
      setLoading(false);
    }
    // will return this after successfull database call. BROKEN FOR NON-USERSS
}

// Search for User based on email. Don't use offlineFetch here.
const userSearch = async (data) => {
  try {
    const response = await fetch(domain+"/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include',
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.error || "User search failed";
      throw new Error(errorMessage);
    }

    const userData = await response.json();
    // Process the user data or perform actions based on the response
    return userData;
  } catch (error) {
    console.log("in catch area of userSearch function");
    console.log(error);
    // Handle error scenario, display an error message, etc.
    throw error;
  }
};

  // SIGNUP FORM STUFF
  function showSignUpForm() {
    if (signup) {
      showSignup(false);
    } else {
      showSignup(true);
      showLogin(false);
    }
  }

  function validateSignUpForm() {
    return (fields.signupName.length > 0 &&
      fields.signupEmail.length > 0 &&
      fields.signupPass.length > 0 &&
      fields.signupPassCheck.length > 0);
  }

  // Create new user and associated blank workouts, programs, and exercise documents.
  // Don't use offlineFetch here.
const userCreate = async (newUser) => {
  try {
    const response = await fetch(domain+"/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include',
      body: JSON.stringify(newUser),
    });
    let errorResponse;
    if (!response.ok) {
      if (response.status === 400) {
        errorResponse = await response.text();
      }else{
        errorResponse = await response.json();
      }
      const errorMessage = errorResponse.error || "User creation failed";
      throw new Error(errorMessage);
    }

    const userData = await response.json();
    // Process the user data or perform actions based on the response
    return userData;
  } catch (error) {
    console.log("in catch area of userCreate function");
    console.log(error);
    // Handle error scenario, display an error message, etc.
    throw error;
  }
};

  async function handleSignUpSubmit(event) {
    event.preventDefault();
    try{
        setSignupButtonDisabled(true);
        setLoading(true);
        // check for valid email
        if (fields.signupPass === fields.signupPassCheck) {
                // Check if the registration code is provided
          if (!fields.registrationCode) {
            alert("Please enter a registration code.");
            setSignupButtonDisabled(false);
            setLoading(false);
            return;
          }
          let newUser = {
            "name": fields.signupName,
            "email": fields.signupEmail,
            "password": fields.signupPass,
            "registrationCode": fields.registrationCode
          };

          const newUserData = await userCreate(newUser);
          
          // IF NO ERRORS, Store in DB.
              alert("Your account has been successfully created!");
              handleFieldChange({
                "signupName": "",
                "signupEmail": "",
                "signupPass": "",
                "signupPassCheck": ""
              });
  
              // update all user objects state in React Context api
              updateAuth(true);
              updateJWT(newUserData.token || null);
              updateUser(newUserData.user?newUserData.user:"");
              updateUserPrograms(newUserData.programs ? newUserData.programs : "");
              updateUserWorkoutHistory(newUserData.workouts ? newUserData.workouts : "");
              updateUserExercises(newUserData.exercises ? newUserData.exercises : "");
              updateTTExercises(newUserData.ttExercises ? newUserData.ttExercises : null);
              // redirect to dashboard
              setLoading(false);
              history.push("/dashboard");
        }
        else {
          handleFieldChange({
            "signupPass": "",
            "signupPassCheck": ""
          });
          alert("Passwords Don't Match! Please retype both.");
          setSignupButtonDisabled(false);
          setLoading(false);
        }
      
    }catch(error){
      alert(error);
      setSignupButtonDisabled(false);
      setLoading(false);
    }
  }
  

  return (
    <div className="login-container">
      <div id="logo">
        <img src={LOGO} alt="logo" className="logo-image" />
      </div>
      <div id="sign-up-or-login">
      {loading && <LoadingModal/>}
        <button onClick={showSignUpForm}>
          <span>Sign Up</span>
        </button>
        <button onClick={showLoginForm}>
          <span>Log In</span>
        </button>
      </div>
      <div id="sign-up" style={{ display: signup ? "block" : "none" }}>
        <form id="sign-up" onSubmit={(event) => handleSignUpSubmit(event)}>
        <label htmlFor="registrationCode">Registration Code: </label>
          <input id="registrationCode"
            name="signupRegistrationCode"
            type="text"
            placeholder="Enter code..."
            onChange={handleFieldChange}
            value={fields.registrationCode} />
          <label htmlFor="signupName">Name: </label>
          <input id="signupName"
            name="signupName"
            type="text"
            placeholder="Enter name..."
            onChange={handleFieldChange}
            value={fields.signupName} />
          <label htmlFor="signupEmail">Email: </label>
          <input id="signupEmail"
            name="signupEmail"
            type="email"
            placeholder="Enter email..."
            onChange={handleFieldChange}
            value={fields.signupEmail}
            required />
          <label htmlFor="signupPass">Password: </label>
          <input id="signupPass"
            name="signupPass"
            placeholder="Enter password..."
            onChange={handleFieldChange}
            value={fields.signupPass} />
          <label htmlFor="signupPassCheck">Retype Password: </label>
          <input id="signupPassCheck"
            name="signupPassCheck"
            placeholder="Retype password..."
            onChange={handleFieldChange}
            value={fields.signupPassCheck} />
          <button className="dashboard-btn" disabled={!validateSignUpForm() || signupButtonDisabled} type="submit">
            Sign-Up
          </button>
        </form>
      </div>
      <div id="login" style={{ display: login ? "block" : "none" }}>
        <form id="login" onSubmit={handleLoginSubmit}>
          <label
            htmlFor="loginEmail"
            name="loginEmail">
            Email:
          </label>
          <input id="loginEmail"
            name="loginEmail"
            placeholder="Enter Email..."
            onChange={handleFieldChange}
            value={fields.loginEmail}
          />
          <label
            name="loginPass"
            htmlFor="loginPass">
            Password:
          </label>
          <div id="login-password">
            <input id="loginPass"
              type={showPassword ? "text" : "password"}
              name="loginPass"
              placeholder="Enter password..."
              onChange={handleFieldChange}
              value={fields.loginPass}
            />
            <span toggle="#password-field"
              className={showPassword ? "fa fa-eye fa-eye-slash field-icon" : "fa fa-fw fa-eye field-icon"}
              onClick={showThePassword}
            />
          </div>
          <button className="dashboard-btn" disabled={!validateLoginForm()} type="submit">
            LOGIN
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
