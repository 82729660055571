import { useContext } from "react";
import { AppStateContext } from "../libs/AppStateContext";
import { openDB } from 'idb';

const DB_NAME = 'offline-requests';
const STORE_NAME = 'requests';

async function saveRequest(url, options) {
  const db = await openDB(DB_NAME, 1, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
    },
  });
  await db.add(STORE_NAME, { url, options });
}

export async function offlineFetch(url, options, localStorageKey, localStorageData, context) {
  const { 
    updateUser, 
    updateUserPrograms, 
    updateUserExercises, 
    updateTTExercises, 
    updateUserWorkoutHistory, 
    updateAuth,
    updateJWT,
    updatePendingRequests,
    clearAppState
  } = context;

  // Check if the application is online or offline
  const isOnline = () => window.navigator.onLine;

  // Update local storage and context
  const updateLocalStorageAndContext = () => {
    // Update the context based on the key
    switch (localStorageKey) {
      case 'user':
        updateUser(localStorageData);
        break;
      case 'userPrograms':
        updateUserPrograms(localStorageData);
        break;
      case 'userExercises':
        updateUserExercises(localStorageData);
        break;
      case 'ttExercises':
        updateTTExercises(localStorageData);
        break;
      case 'userWorkoutHistory':
        updateUserWorkoutHistory(localStorageData);
        break;
      case 'isAuthenticated':
        updateAuth(localStorageData);
        break;
      case 'jwt':
        updateJWT(localStorageData);
        break;
      case 'pendingRequests':
        updatePendingRequests(localStorageData);
        break;
      default:
        break;
    }
  };

  // This works because of Background Sync in the service worker -
  // store the request in IndexedDB and retry it later.
  try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.error || "Request failed";
        throw new Error(errorMessage);
      }
      const data = await response.json();
      // Update local storage and context with the fetched data
      updateLocalStorageAndContext();
      console.log('Fetch successful:', data);
      return data;
      
    }catch (error) {
      console.error('Fetch failed. Background Sync should take care of it when Network back on:', error);
      updateLocalStorageAndContext();
      // Save the request details for background sync
      await saveRequest(url, options);
      // Register the sync event with the tag 'mongo-update-queue'
      if ('serviceWorker' in navigator && 'SyncManager' in window) {
        navigator.serviceWorker.ready.then(registration => {
          return registration.sync.register('mongo-update-queue');
        }).then(() => {
          console.log('Sync event registered');
        }).catch(err => {
          console.error('Sync registration failed', err);
        });
      }
  
      // Return a resolved promise or default value
      return Promise.resolve({ message: 'Fetch failed, will retry in background' });
    }
  }

// This is needed to avoid the REACT context error 
/**
 * 
 * @returns  Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:

You might have mismatching versions of React and the renderer (such as React DOM)
You might be breaking the Rules of Hooks
You might have more than one copy of React in the same app See https://react.dev/link/invalid-hook-call for tips about how to debug and fix this problem.
The error you're encountering is due to the fact that useContext (a React hook) is being called outside of a React component or a custom hook. Hooks can only be called inside the body of a function component or a custom hook.

To resolve this issue, you need to refactor the offlineFetch function to avoid using hooks directly. Instead, you can pass the necessary context values as arguments to the offlineFetch function. 
*/
export function useOfflineFetch() {
  const context = useContext(AppStateContext);
  return (url, options, localStorageKey, localStorageData) => offlineFetch(url, options, localStorageKey, localStorageData, context);
}