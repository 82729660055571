import React, {useState} from "react";

export default function ExportRawData({inputJSON,selectedProgram}) {
  // Exports the current program workout history or all workout history
  const selectedProgramHistory =  inputJSON.userWorkoutHistory.filter((p)=>p.programID===selectedProgram.programID);
  const[exportSelection, setExportSelection]=useState("all");

  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    const arrData = Array.isArray(JSONData) ? JSONData : [JSONData];
    let CSV = "";

    if (ShowLabel) {
      const labels = [
        "timestamp",
        "program_name",
        "workout_name",
        "exercise_name",
        "reps",
        "weight",
        "duration",
        "rest"
      ];
      CSV += labels.join(",") + "\r\n";
    }

    const extractExerciseSets = (exercise, timestamp, programName, workoutName) => {
      const {
        exerciseName,
        typeSelected,
        sets,
        exercisesInCircuit
      } = exercise;

      if (typeSelected === "resistance") {
        for (const set of sets) {
          const {
            reps,
            weight,
            duration,
            rest
          } = set;
          const rowData = [
            timestamp,
            programName,
            workoutName,
            exerciseName,
            reps,
            weight,
            duration,
            rest
          ];
          CSV += rowData.join(",") + "\r\n";
        }
      } else if (typeSelected === "circuit") {
        for (const circuitExercise of exercisesInCircuit) {
          const {
            exerciseName: circuitExerciseName,
            sets: circuitSets
          } = circuitExercise;
          for (const set of circuitSets) {
            const {
              reps,
              weight,
              duration,
              rest
            } = set;
            const rowData = [
              timestamp,
              programName,
              workoutName,
              `${exerciseName} (${circuitExerciseName})`,
              reps,
              weight,
              duration,
              rest
            ];
            CSV += rowData.join(",") + "\r\n";
          }
        }
      }
    };

    for (const workout of arrData) {
      const { timestamp, programName, workoutName, workoutExercises } = workout;
      for (const exercise of workoutExercises) {
        extractExerciseSets(exercise, timestamp, programName, workoutName);
      }
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    const fileName = `WorkoutData_${ReportTitle.replace(/ /g, "_")}`;
    const uri = "data:text/csv;charset=utf-8," + escape(CSV);

    const link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="exportRawData">
        <div className="row">
        <div className="col">
          <select
              id="all_current"
              name="all_current"
              value={exportSelection}
              onChange={(event) => setExportSelection(event.target.value)}
            >
              <option value="all">All</option>
              <option value="current">Current Program</option>
          </select>
        </div>
        <div className="col">
        <button className="dashboard-btn"
        style={{margin:"8px"}}
          onClick={() =>
            JSONToCSVConvertor(exportSelection==="all"?
              inputJSON.userWorkoutHistory:selectedProgramHistory,
              "WorkoutHistory",
              true
            )
          }
        >
          Download CSV
        </button>
        </div>
        </div>
    </div>
  );
}
