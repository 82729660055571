import React, {useContext,useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
import LOGO from "./template-trainer-logo-1.gif";
import {
    useLocation,
    useHistory,
    Link
} from "react-router-dom";

export default function NavbarComponent(props) {
  const {
    user,
    userPrograms,
    isAuthenticated,
    updateAuth,
    clearAppState
  } = useContext(AppStateContext);
  const history = useHistory();
  const location = useLocation();
  if (location.pathname === '/') {
    return null; // Don't render the Navbar on the login page
  }

  const [menuOpen, setMenuOpen] = useState(false); // State variable to track menu open/closed

  const handleLogout = () => {
    clearAppState();
    history.push("/");
    setMenuOpen(false); // Close the menu after logout
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen); // Toggle the menu open/closed
  };

  return (
    <Navbar expand="lg" className="navbar navbar-light" style={{ backgroundColor: "#fff" }}>
      <Container>
        <Link to="/dashboard" className="navbar-brand">
          <img src={LOGO} alt="Logo" height="60" />
        </Link>
        <Navbar.Toggle aria-controls="navbar-nav" onClick={handleMenuToggle} /> {/* Add onClick to handle the menu toggle */}
        <Navbar.Collapse id="navbar-nav" className={menuOpen ? "show" : ""}> {/* Conditionally add "show" class */}
          <Nav className="ms-auto">
            <Link to="/programcreator" className="nav-link" onClick={handleMenuToggle}>Program Editor</Link> {/* Add onClick to close the menu */}
            <Link to="/exercise-library" className="nav-link" onClick={handleMenuToggle}>Exercise Library</Link> {/* Add onClick to close the menu */}
            <a href="https://www.reddit.com/r/TemplateTrainer/" className="nav-link" target="_blank" rel="noopener noreferrer" onClick={handleMenuToggle}>Help</a>
            <NavDropdown title={user ? user.name : "Something Broke"} id="user-dropdown">
              <Link to="/usersettings" className="dropdown-item" onClick={handleMenuToggle}>Profile</Link> {/* Add onClick to close the menu */}
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
