import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      let reg;
      if (import.meta.env?.DEV) {
        reg = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Development service worker registered! 😎', reg);
      } else {
        reg = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Production service worker registered! 😎', reg);
      }

      // Listen for updates to the service worker
      reg.onupdatefound = () => {
        const installingWorker = reg.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('New content is available; please refresh.');
              // Optionally, prompt the user to refresh the page
            } else {
              console.log('Content is cached for offline use.');
            }
          }
        };
      };
    } catch (err) {
      console.log('😥 Service worker registration failed: ', err);
    }
  });
}
