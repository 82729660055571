import React, { useState, Fragment, useEffect,useContext } from "react";
import Icon from "../assets/svgs/icon.js"
import NewExerciseEditorForm from "./AddNewExercise";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
import "./ExerciseLibrary.css"
// Reusable component for displaying exercises
import ExerciseList from './ExerciseList';

/***
 * This is a display page that holds all built in exercises, 
 * create/edit custom exercises, and exercise physiology info
 * */

export default function ExerciseLibrary(props) {
 console.log("In Exercise Library");

 // history is passed in by react router. keeps record of pages visited
 const [history] = useState(props.history);
  // Global State React Context API
  const {user,
    userExercises,
    ttExercises,
    isAuthenticated
  } = useContext(AppStateContext);
  // Don't allow non-logged in users to access any pages other than login
  if (!isAuthenticated) {
    // redirect to login
    history.push("/");
  }
    const [view, setView] = useState({
        "exerciseLibrary": true,
        "anatomy": false,
        "customExercises": false
    })
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredExercises, setFilteredExercises] = useState([]);
    const [selectedExercise, setSelectedExercise] = useState(null);
    
    // Combine template trainer exercises and user exercises
    useEffect(() => {
      setFilteredExercises([...ttExercises.TemplateTrainerExercises, ...userExercises.userExercises]);
    }, [ttExercises, userExercises]);

    // Helper functions
    const handleSearch = (e) => {
      setSelectedExercise(null);
      const searchTerm = e.target.value;
      setSearchTerm(searchTerm);
      
      const combinedExercises = [...ttExercises.TemplateTrainerExercises, ...userExercises.userExercises];
      const filteredExercises = combinedExercises.filter(exercise => {
        const keysToSearch = ["musclesInvolved", "equipment", "exerciseName"];
        for (let key in exercise) {
          if (keysToSearch.includes(key)) {
            const exerciseValue = exercise[key];
            if (typeof exerciseValue === 'string' && exerciseValue.toLowerCase().includes(searchTerm.toLowerCase())) {
              return true;
            }
            if (Array.isArray(exerciseValue) && exerciseValue.some(value => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase()))) {
              return true;
            }
          }
        }
        return false;
      });
      setFilteredExercises(filteredExercises);
    };
    
    
      const handleExerciseClick = (exercise) => {
        setSelectedExercise(exercise);
        setSearchTerm("");
      };

    // change the sections displayed
    function changeView(event) {
        let viewCopy = { ...view };
        for (let key in viewCopy) {
            if (event.target.id === key) {
                viewCopy[key] = true;

            } else {
                viewCopy[key] = false;
            }
        }
        setView(viewCopy);
    }

    return (
        <div className="container">
            <h1>Exercise Library</h1>
            <p>
                Here you can search for an exercise based on main
                muscle groups worked, equipment, or name
                to determine if an exercise is right for your workout.
                We have included common lifts in our exercise database,
                but if you don't see an exercise, or need a variation of
                an existing exercise, you can create
                custom exercises as well.
            </p> 
            <div id="buttons" style={{ display: 'flex', justifyContent: 'center' }}>
              <button id="exerciseLibrary"
              className="dashboard-btn"
                  onClick={(event) => { changeView(event) }}>Search Exercises
              </button>

              <button id="customExercises"
              className="dashboard-btn"
                  onClick={(event) => { changeView(event) }}>Create Exercises
              </button>
            </div>
            <div
                id="exerciseLibrary"
                style={{ display: view["exerciseLibrary"] ? "block" : "none" }}>
      <input
        type="text"
        placeholder="Search exercises"
        value={searchTerm}
        onChange={handleSearch}
      />
      {selectedExercise && (
        <div className="exercise-information">
      <h2 style={{ display: "flex", justifyContent: "center" }}>{selectedExercise.exerciseName}</h2>
      <div className="exercise-details">
        <p style={{ display: "flex", justifyContent: "center" }}>
          <span className="exercise-difficulty"><strong>Type: </strong> {selectedExercise.type} </span>
          <span className="exercise-difficulty"><strong>Difficulty: </strong> {selectedExercise.difficulty}</span>
        </p>
        <p>
          <strong>Equipment:</strong> {selectedExercise.equipment.join(", ")}
        </p>
        <p>
          <strong>Muscles Involved:</strong> {selectedExercise.musclesInvolved.join(", ")}
        </p>
        <p>
          <strong>Instructions:</strong> {selectedExercise.instructions}
        </p>
      </div>
    </div>
      )}
<ExerciseList exercises={filteredExercises} clickHandler={handleExerciseClick}/>
            </div>
            <div id="customExercises"
                style={{ display: view["customExercises"] ? "block" : "none" }}>
                {view["customExercises"] ? <NewExerciseEditorForm /> : ""}
            </div>
        </div>
    )
}