import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
// MY CUSTOM FUNCTION
import { generateUniqueExerciseID } from "../libs/generateUniqueID";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
import "./AddNewExercise.css";
// Reusable component for displaying exercises
import ExerciseList from './ExerciseList';
import { useOfflineFetch } from '../libs/offlineFetch';

//ADD OR EDIT EXERCISES

// Allowed values
const allowedMuscles = [
  "abdominals",
  "biceps",
  "triceps",
  "forearms",
  "quadriceps",
  "hamstrings",
  "calves",
  "glutes",
  "chest",
  "back",
  "shoulders",
  "neck"
];

const allowedTypes = [
  "strength",
  "cardio",
  "flexibility",
  "balance",
  "general_fitness",
  "plyometrics",
  "calisthenics",
  "strongman",
  "powerlifting",
  "olympic_weightlifting"
];

const allowedDifficulties = [
  "beginner",
  "intermediate",
  "advanced"
];

const validateExercise = (exercise) => {
  const errors = [];

  // Validate musclesInvolved
  const invalidMuscles = exercise.musclesInvolved.filter(muscle => !allowedMuscles.includes(muscle));
  if (invalidMuscles.length > 0) {
    errors.push(`Invalid muscles involved: ${invalidMuscles.join(', ')}`);
  }

  // Validate type
  if (!allowedTypes.includes(exercise.type)) {
    errors.push(`Invalid type: ${exercise.type}`);
  }

  // Validate difficulty
  if (!allowedDifficulties.includes(exercise.difficulty)) {
    errors.push(`Invalid difficulty: ${exercise.difficulty}`);
  }

  return errors;
};

export default function NewExerciseEditorForm(props) {
  const {
    userExercises,
    ttExercises,
    isAuthenticated,
    jwt,
    updateUserExercises,
  } = useContext(AppStateContext);
  const domain = "https://api.templatetrainer.com";

  console.log("In new exercise editor");
  console.log(userExercises);

  // This component's state
  const [newExercise, setNewExercise] = useState({
    exerciseID: "",
    exerciseName: "",
    musclesInvolved: [],
    equipment: [],
    type:"",
    difficulty:"",
    instructions:""
  });
  const [view,changeView] = useState({"newExercise":true,"editExercise":false});
  const [error, setError] = useState('');
  /* *************************ALL HELPER FUNCTIONS ************************************/
  function handleInputChange(event, index) {
    const {name,value} = event.target;
    // Update an existing exercise
    console.log(name);
    console.log(index);
    if(index !== undefined){
      let copyOfUserExercises= {...userExercises};
      // split string into array
      if (value.includes(",")) {
        copyOfUserExercises.userExercises[index][name]  = value.split(",");
      }else{
        copyOfUserExercises.userExercises[index][name] = value; 
      }
      //Update Global State
      updateUserExercises(copyOfUserExercises);
      //TODO Update database
    }else{
    // create copy of obj
    let copyOfExercise = { ...newExercise };
    copyOfExercise[event.target.name] = event.target.value;
    setNewExercise(copyOfExercise);
    console.log(index);
    console.log(value);
    }
  }

  function handleAddNewExercise(event) {
    event.preventDefault();

    const combinedExercises = [...ttExercises.TemplateTrainerExercises, ...userExercises.userExercises];
    const copyOfExercise = { ...newExercise };
    
    // Split equipment and musclesInvolved strings into arrays
    copyOfExercise.equipment = copyOfExercise.equipment.includes(",") ? copyOfExercise.equipment.split(",") : [copyOfExercise.equipment];
    copyOfExercise.musclesInvolved = copyOfExercise.musclesInvolved.includes(",") ? copyOfExercise.musclesInvolved.split(",") : [copyOfExercise.musclesInvolved];
  
    // Validate exercise
    const errors = validateExercise(copyOfExercise);
    if (errors.length > 0) {
      setError(errors.join('. '));
      return;
    }

    // Clear error and proceed with adding the exercise
    setError('');

    // Generate an ID if there isn't one (new exercise), otherwise update the existing exercise
    if (copyOfExercise.exerciseID === "") {
      copyOfExercise.exerciseID = generateUniqueExerciseID("exercise", combinedExercises);
      userExercises.userExercises.push(copyOfExercise);
    } else {
      const exIndex = userExercises.userExercises.findIndex(e => e.exerciseID === copyOfExercise.exerciseID);
      if (exIndex !== -1) {
        userExercises.userExercises[exIndex] = copyOfExercise;
      }
    }
    
    // Update user exercises and database
    const userExercisesCopy = { ...userExercises };
    // updateUserExercises(userExercisesCopy);
    updateDbExercises(userExercisesCopy);
  
    // Clear inputs
    const freshInputs = {
      exerciseID: "",
      exerciseName: "",
      musclesInvolved: [],
      equipment: [],
      type: "",
      difficulty: "",
      instructions: ""
    };
    setNewExercise(freshInputs);
    
    alert("New Exercise Added");
  }
  
// Updates the Workout History in DB and Workout History in React Context Api
const offlineFetch = useOfflineFetch();

// Updates the User Exercises in DB
const  updateDbExercises = async (allUserExercises) => {
  const url = domain+"/updateExercises";
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    },
    credentials: 'include', // Set this to true to include cookies in the request
    body: JSON.stringify(allUserExercises),
   }
try {
  // (url, options, localStorageKey, localStorageData)
const response = await offlineFetch(url, options,'userExercises', allUserExercises);

if (!response.ok) {
 const errorResponse = await response.json();
 const errorMessage = errorResponse.error || "Update Exercises failed";
 throw new Error(errorMessage);
}

} catch (error) {
console.log(error);
// Handle error scenario, display an error message, etc.
throw error;
}
};

function deleteExercise(e) {
  e.preventDefault();

  // Check if exerciseID is empty
  if (newExercise.exerciseID === "") {
alert("Exercise not yet saved in database.");
    return;
  }

  const isConfirmed = window.confirm("Are you sure you want to delete this exercise?");
  if (!isConfirmed) {
    return;
  }

  // Find the index of the exercise to delete
  const exerciseIndex = userExercises.userExercises.findIndex(exercise => exercise.exerciseID === newExercise.exerciseID);

  // If the exercise is found, remove it
  if (exerciseIndex !== -1) {
    let userExercisesCopy = { ...userExercises };
    userExercisesCopy.userExercises.splice(exerciseIndex, 1);

    // Update global state and database
    updateDbExercises(userExercisesCopy);

    // Clear inputs
    setNewExercise({
      exerciseID: "",
      exerciseName: "",
      musclesInvolved: "",
      equipment: [],
      type: "",
      difficulty: "",
      instructions: ""
    });
  } else {
    setError('Exercise not found.');
  }
}

  /* ************************  RETURN THE COMPONENT *****************************/

  return (
    <div id="container">
    <div id="newExercise"
    style={{display:view.newExercise?"block":"none"}}>
    <h3> Create New Exercise </h3>
    {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className="new-exercise">
        <label>Exercise Name: </label>
        <input
          id=""
          type="text"
          value={newExercise.exerciseName}
          name="exerciseName"
          onChange={(event) => handleInputChange(event)}
        />
      </div>
      <div className="new-exercise">
        <label>Muscles Involved: </label>
        <input
          id=""
          type="text"
          value={newExercise.musclesInvolved}
          name="musclesInvolved"
          onChange={(event) => handleInputChange(event)}
        />
      </div>
      <div className="new-exercise">
        <label>Equipment Needed: </label>
        <input
          id=""
          type="text"
          value={newExercise.equipment}
          name="equipment"
          onChange={(event) => handleInputChange(event)}
        />
      </div>
      <div className="new-exercise">
        <label>Type: </label>
        <select
          id="type"
          defaultValue={newExercise.type}
          name="type"
          onChange={(event) => handleInputChange(event)}
        >
          <option key="strength" value="strength">Strength</option>
          <option key="general_fitness" value="general_fitness">General Fitness</option>
          <option key="plyometrics" value="plyometrics">Plyometrics</option>
          <option key="strongman" value="strongman">Strongman</option>
          <option key="powerlifting" value="powerlifting">Powerlifting</option>
          <option key="olympic_weightlifting" value="olympic_weightlifting">Olympic Weightlifting</option>
        </select>
      </div>
      <div className="new-exercise">
        <label>Difficulty</label>
        <select
          id="difficulty"
          type="text"
          defaultValue={newExercise.difficulty}
          name="difficulty"
          onChange={(event) => handleInputChange(event)}
        >
          <option key="beginner" value="beginner">Beginner</option>
          <option key="intermediate" value="intermediate">Intermediate</option>
          <option key="advanced" value="advanced">Advanced</option>
        </select>
      </div>
      <div className="new-exercise">
        <label>Instructions</label>
        <input
          id=""
          type="text"
          value={newExercise.instructions}
          name="instructions"
          onChange={(event) => handleInputChange(event)}
        />
      </div>
      <button className="dashboard-btn" onClick={(event) => handleAddNewExercise(event)}>
        Save Exercise
      </button>
      <button className="dashboard-btn" id="deleteExercise" onClick={(e)=> deleteExercise(e)}>
                    <i
                    className="fa fa-trash"
                    aria-hidden="true"/>
      </button>
    </div>
      <div id="exerciseEditor" style={{ display: userExercises.userExercises.length > 0 ? "block" : "none" }}>
      <h2>Existing Exercises</h2>
      <ExerciseList 
        exercises={userExercises.userExercises} 
        clickHandler={setNewExercise}
      />
    </div>
    </div>
  );
}
