import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartComponent from './ChartComponent';

const ChartPage = () => {
  const location = useLocation();
  const { workoutData, selectedExercise } = location.state;

  return (
    <div>
      <h1>Exercise Trends: {selectedExercise}</h1>
      <ChartComponent workoutData={workoutData} selectedExercise={selectedExercise} />
    </div>
  );
};

export default ChartPage;