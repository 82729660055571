import React, { useState, Fragment, useEffect, useRef, useContext } from "react";
import "./LogicEditorNew.css";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ModifyLogic = ({ programFields, updateProgramFields }) => {
  console.log("Logic Component Rendering");
  const {
    user,
    isAuthenticated
  } = useContext(AppStateContext);
  // Used to create accordion effect in Workout Display list
  const [expandedWorkoutIndex, setExpandedWorkoutIndex] = useState(null);
  const [expandedpriorWorkoutIndex, setExpandedPriorWorkoutIndex] = useState(null);
    // Local state for logic editor for specific workout in exerciseLogic array. For full workout logic, use programFields.exerciseLogic
    // Can search full workout logic by applyToWorkoutScheduleIndex, then exercisesAffected.find(exerciseID) since it can only appear once.
  const [logic, setLogic] = useState({"applyToWorkoutScheduleIndex":0,"exercisesAffected":[{}]});
  console.log(logic);
  // Use to grab sets in Analyze block 
  const [analyzedWorkout, setAnalyzedWorkout] = useState({});
  console.log(analyzedWorkout);
  // The exercise selected to apply logic to.
  const [selectedExercise, setSelectedExercise] = useState({});

  useEffect(()=>{
    // Display is based on exercise selected from right side Workout Schedule
    renderExerciseLogic();
  }, [logic]);

  // Display logic editor for selected exercise in selected workout in workout schedule
  /*
  ifs/thens/elses.target can be: weight,reps,duration,rest,rounds
  Choose specific sets like set 1
  Possible bug arises if exercise is in workout more than once.
  ifs.operators can be: <,<=,=,>=,>
  ifs/thens/elses.values can be: any integer
  ifs/thens/elses.unit can be: unit (integer), or % of record value (should be converted to nearest integer); if unit, recordType ignored.
  ifs/thens/elses.recordType can be: any record value (1RM, maxDuration, maxReps, minRest)
  thens/elses.actions can be: +,- (set corresponding values in value array to 0 to do nothing, or leave blank)

  AllorAny - All requires every if statement to eval true to apply then statement. Any requires just one.
  */

  // Start of helper functions ----------------------------------
  const handleSelectChange = (event, exerciseIndex, analyzeBlockIndex, ifIndex) => {
    const { name, value, selectedOptions} = event.target;
      const updatedLogic = { ...logic };
      const exercise = updatedLogic.exercisesAffected[exerciseIndex];
      if (name === "allOrAnyAnalyze") {
        exercise.allOrAnyAnalyze = value;
      }else if(name === "prevSetValSource"){
        exercise.prevSetValSource = value;
      }else if(name==="allOrAnyIfs"){
        exercise.analyze[analyzeBlockIndex].allOrAnyIfs=value;
      } else if (name === "analyzeWorkoutScheduleIndex") {
        // Deal with default Select Value
        if(value===""){
          return;
        }
        exercise.analyze[analyzeBlockIndex].analyzeWorkoutScheduleIndex = parseInt(value);
        // Get Workout ID from index in workout Schedule
        const workout = programFields.workoutsInProgram.find((w)=>w.workoutID ===programFields.workoutSchedule[parseInt(value)]);
        setAnalyzedWorkout(workout);
      } else if (name === "analyzeWorkoutExercise") {
        // Deal with default Select Value
        console.log(value);
        if(value===""){
          return;
        }
        const exerciseToAnalyze = analyzedWorkout.workoutExercises.find((exercise) => exercise.exerciseID === value);
        exercise.analyze[analyzeBlockIndex].exerciseName = exerciseToAnalyze.exerciseName;
        exercise.analyze[analyzeBlockIndex].exerciseID = exerciseToAnalyze.exerciseID;
      } else if (name === "setsToAnalyze") {
        const selectedValues = Array.from(selectedOptions).map((option) => option.value);
        exercise.analyze[analyzeBlockIndex].ifs[ifIndex].setsToAnalyze = selectedValues;
      } else if (name === "target") {
        exercise.analyze[analyzeBlockIndex].ifs[ifIndex].target = value;
      } else if (name === "operator") {
        exercise.analyze[analyzeBlockIndex].ifs[ifIndex].operator = value;
      } else if (name === "targetValue") {
        exercise.analyze[analyzeBlockIndex].ifs[ifIndex].targetValue = parseInt(value);
      } else if (name === "targetUnit") {
        exercise.analyze[analyzeBlockIndex].ifs[ifIndex].targetUnit = value;
      } else if (name === "recordType") {
        exercise.analyze[analyzeBlockIndex].ifs[ifIndex].recordType = value;
      } else if (name === "setsToApplyThen"){
        // analyzeBlockIndex is passed the variable for thenBlockIndex / elseBlockIndex
        const selectedValues = Array.from(selectedOptions).map((option) => option.value);
        exercise.apply.then[analyzeBlockIndex].setsToApply = selectedValues;
      } else if (name==="thenTarget"){
        exercise.apply.then[analyzeBlockIndex].target = value;
      } else if (name==="thenAction"){
        exercise.apply.then[analyzeBlockIndex].action = value;
      } else if (name==="thenActionValue"){
        exercise.apply.then[analyzeBlockIndex].actionValue = parseInt(value);
      } else if (name==="thenActionUnit"){
        exercise.apply.then[analyzeBlockIndex].actionUnit = value;
      } else if (name==="thenRecordType"){
        exercise.apply.then[analyzeBlockIndex].recordType = value;
      }else if (name === "setsToApplyElse"){
        // analyzeBlockIndex is passed the variable for thenBlockIndex / elseBlockIndex
        const selectedValues = Array.from(selectedOptions).map((option) => option.value);
        exercise.apply.else[analyzeBlockIndex].setsToApply = selectedValues;
      } else if (name==="elseTarget"){
        exercise.apply.else[analyzeBlockIndex].target = value;
      } else if (name==="elseAction"){
        exercise.apply.else[analyzeBlockIndex].action = value;
      } else if (name==="elseActionValue"){
        exercise.apply.else[analyzeBlockIndex].actionValue = parseInt(value);
      } else if (name==="elseActionUnit"){
        exercise.apply.else[analyzeBlockIndex].actionUnit = value;
      } else if (name==="elseRecordType"){
        exercise.apply.else[analyzeBlockIndex].recordType = value;
      }

      setLogic(updatedLogic);
  };
  
  /**
   * Creates a new workout/exercise object in exerciseLogic array
   * @param {*} workoutScheduleIndex - index of workout in workout schedule to which logic applies
   * @param {*} exercise - exercise obj to which logic applies
   */
  const handleAddWorkout = (workoutScheduleIndex,exercise) =>{
    let newWorkoutLogicObject = {
      applyToWorkoutScheduleIndex: workoutScheduleIndex,
      exercisesAffected: []
    };
    handleAddExercise(newWorkoutLogicObject,exercise);
  }

  const handleDeleteWorkout = (workoutScheduleIndex) =>{
    let programCopy={...programFields};
    let applyIndex = programCopy.exerciseLogic.findIndex((w)=>w.applyToWorkoutScheduleIndex=== workoutScheduleIndex);
    if(applyIndex !==-1){
      programCopy.exerciseLogic.splice(applyIndex,1);
      updateProgramFields(programCopy);
    }

  }

// Start Exercise modification functions ------------------------------------

  // Add new exercise to exercisesAffected array when the workout already exists in exerciseLogic array. Need workout schedule index and exercise info
  /**
   * @param {*} exerciseLogicObject - existing exercise Logic object in exerciseLogic array.
   * @param {*} exercise  - exercise obj to which logic applies
   * @param {*} exerciseData.prevSetValSource - allows set values to be taken from the most recent version of logged exercise, even from
   * a different workout. This is how to solve the Starting Strength A,B,A format where you add weight to every occurence of an exercise
   */
  const handleAddExercise = (exerciseLogicObject, exercise) => {
    let exerciseData = {exerciseName: exercise.exerciseName,
    exerciseID: exercise.exerciseID,
    allOrAnyAnalyze: 'all',
    prevSetValSource:'last_exercise',
    analyze:[],
    apply:{then:[],else:[]}
    }
    // Change exerciseLogic Object
    const newData = {...exerciseLogicObject};
    newData.exercisesAffected.push(exerciseData);
    setLogic(newData);
    //Update Program Component state. may be redundant
    const programCopy = {...programFields};
    let workoutIndex = programCopy.exerciseLogic.findIndex((logic)=>logic.applyToWorkoutScheduleIndex === exerciseLogicObject.applyToWorkoutScheduleIndex);
    // workout is in exercise Logic
    if(workoutIndex !== -1){
      programCopy.exerciseLogic[workoutIndex]=newData;
    }else{
      programCopy.exerciseLogic.push(newData);
    }
    updateProgramFields(programCopy);
  };

  const handleRemoveExercise = (e, exercise) => {
      e.preventDefault();
      // The position in the newData.exercisesAffected is not necessarily the position of the correct exercise
      const newData = {...logic};
      let exerciseIndex = newData.exercisesAffected.findIndex((e)=>e.exerciseID===exercise.exerciseID);
      newData.exercisesAffected.splice(exerciseIndex, 1);
      //Update Program Component state. may be redundant
      const programCopy = {...programFields};
      let workoutIndex = programCopy.exerciseLogic.findIndex((logic)=>logic.applyToWorkoutScheduleIndex === newData.applyToWorkoutScheduleIndex);
      // workout is in exercise Logic
      if(workoutIndex !== -1){
        programCopy.exerciseLogic[workoutIndex]=newData;
        updateProgramFields(programCopy);
      }
      // Update local state
      setLogic(newData);
  };

// End Exercise modification functions ------------------------------------

// Start modify Analyze block functions -----------------------------------
const handleAddAnalyze = (event, exerciseIndex) => {
  event.preventDefault();
    const newData = {...logic };
    console.log("In analyze block adder");
    console.log(newData);
    // This is a new Analyze block, so should be empty.
      console.log("pushing into the array");
      newData.exercisesAffected[exerciseIndex].analyze.push({
        analyzeWorkoutScheduleIndex: 0,
        exerciseName: '',
        exerciseID: '',
        allOrAnyIfs: 'all',
        ifs: [
          {
            setsToAnalyze: [0],
            target: 'weight',
            operator: '<',
            targetValue: 0,
            targetUnit: '%',
            recordType: '1RM',
          },
        ],
      });
      setLogic(newData);
};


  const handleRemoveAnalyze = (e, exerciseIndex, analyzeBlockIndex) => {
    e.preventDefault();
    // logic[index].exercisesAffected[affectedExerciseIndex].analyze[analyzeIndex].ifs[ifsIndex]
    // need: index,affectedExerciseIndex,analyzeIndex,ifsIndex
      const newData = { ...logic };
      newData.exercisesAffected[exerciseIndex].analyze.splice(analyzeBlockIndex, 1);
      setLogic(newData);
  };

  const handleAddAnalyzeIfBlock = (e, exerciseIndex, analyzeBlockIndex) => {
    e.preventDefault();
    let ifStatement ={
                        setsToAnalyze: [0],
                        target: 'weight',
                        operator: '<',
                        targetValue: 0,
                        targetUnit: '%',
                        recordType: '1RM',
                      };
      const newData = { ...logic };
      newData.exercisesAffected[exerciseIndex].analyze[analyzeBlockIndex].ifs.push(ifStatement);
      setLogic(newData);
      console.log("Adding Analyze If Block")
      console.log(newData);
  };

  const handleRemoveAnalyzeIfBlock = (e, exerciseIndex, analyzeBlockIndex, ifIndex) => {
    e.preventDefault();
      const newData = { ...logic};
      newData.exercisesAffected[exerciseIndex].analyze[analyzeBlockIndex].ifs.splice(ifIndex, 1);
      setLogic(newData);
  };
  // End modify Analyze block functions -----------------------------------

  // Start modify Apply block functions -----------------------------------
  const handleAddApply = (event, applyToExercise) => {
    const { name, value, selectedOptions} = event.target;
    event.preventDefault();
    const newData = {...logic };
    console.log("In apply block adder");
    console.log(applyToExercise);
    let cosequenceArray = {
      setsToApply: [0],
      target:"weight", 
      action:"+", 
      actionValue:0, 
      actionUnit:"%",
      recordType:"1RM"};

    if (name ==="applyThenBlock"){
      newData.exercisesAffected[applyToExercise].apply.then.push(cosequenceArray);
    } else if(name ==="applyElseBlock"){
      newData.exercisesAffected[applyToExercise].apply.else.push(cosequenceArray);
    }
    console.log(newData);
    setLogic(newData);
  };

  const handleRemoveApply = (event, applyToExercise,arrayIndex) => {
    const { name, value, selectedOptions} = event.target;
    event.preventDefault();
    const newData = {...logic };
    if (name ==="applyThenBlock"){
      newData.exercisesAffected[applyToExercise].apply.then.splice(arrayIndex,1);
    } else if(name ==="applyElseBlock"){
      newData.exercisesAffected[applyToExercise].apply.else.splice(arrayIndex,1);
    }
      console.log(newData);
      setLogic(newData);
  }

  // End of modify apply blocks -------------------------------------

  // Looks for currently existing logic for exercise clicked on in specific workout in workout schedule and updates logic so renderExerciseLogic updates editable interface data
  // If none, it creates new logic and renderExerciseLogic creates a blank editable interface.
  const handleApplyLogicToExercise = (workout,workoutIndex, exerciseIndex) => {
    console.log(`Applying logic to Workout ${workoutIndex + 1}, Exercise ${exerciseIndex + 1}`);
    console.log(workout);
    const exercise = workout.workoutExercises[exerciseIndex];
    setSelectedExercise(exercise);
    // if there is logic, it will have workoutScheduleIndex and exerciseID
    const exerciseLogicIndex = programFields.exerciseLogic.findIndex((w) => w.applyToWorkoutScheduleIndex === workoutIndex);
    // See if there is alreacy an exercise logic workout
    if(exerciseLogicIndex !==-1){
      // see if the exercise selected is in exerciseLogic
      const exerciseAffectedIndex = programFields.exerciseLogic[exerciseLogicIndex].exercisesAffected.findIndex((exercise) => exercise.exerciseID === workout.workoutExercises[exerciseIndex].exerciseID);
      // if there is exercise Logic for specific exercise, display it
      if(exerciseAffectedIndex !==-1){
        console.log("applying logic to exercise");
        setLogic(programFields.exerciseLogic[exerciseLogicIndex]);
      }else{// create new exercise in exercisesAffected Array for the workout. Since exerciseLogicIndex !==-1 there is already an object with same applyToWorkoutScheduleIndex 
        // pass in exerciseLogic object
        let exerciseLogicObject = programFields.exerciseLogic[exerciseLogicIndex];
        handleAddExercise(exerciseLogicObject, exercise); 
      }
    }else{ // No exercise logic workout object, just push a new one with applyToWorkoutScheduleIndex = workoutIndex
      handleAddWorkout(workoutIndex, exercise); 
    }
  };

  const renderWorkouts = () => {
    // find all details of every workout in program using workoutID stored in workoutSchedule
    return programFields.workoutSchedule.map((workoutID, workoutIndex) => {
      const workout = programFields.workoutsInProgram.find((w) => w.workoutID === workoutID);
      const isExpanded = expandedWorkoutIndex === workoutIndex;
      const workoutHasLogic = programFields.exerciseLogic.find((l)=>l.applyToWorkoutScheduleIndex===workoutIndex);
      const exerciseHasLogic = workoutHasLogic ? workoutHasLogic.exercisesAffected :[];
      return (
        <div key={`workout-${workoutIndex}`}>
          <h3 id="workoutSchedule" onClick={(e) => handleWorkoutClick(e,workoutIndex)}>{workoutIndex+1}. {workout.workoutName} 
          {workoutHasLogic && (
            <span className="logic-indicator">
              <FontAwesomeIcon icon={faCheckCircle} className="checkmark-icon" />
            </span>
          )}</h3>
          {workoutHasLogic && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Delete Workout From Logic</Tooltip>}
                      >
                        <button
                          id="deleteWorkoutFromLogic"
                          className="delete-button"
                          onClick={() => handleDeleteWorkout(workoutIndex)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </OverlayTrigger>
                    )}
            {workoutIndex !==0 && workout.workoutExercises.map((exercise, exerciseIndex) => (
              <Fragment>
                {isExpanded && (
                  <ol start={exerciseIndex+1}>
                  <li key={`exercise-${exerciseIndex}`}>
                  <div className="list-item-content">
                    <h3>{exercise.exerciseName}</h3>
                    <button
                    className="dashboard-btn"
                      type="button"
                      onClick={() => handleApplyLogicToExercise(workout,workoutIndex,exerciseIndex)}
                    >
                      Logic Editor
                    </button>
                    {exerciseHasLogic.find((e) => e.exerciseID === exercise.exerciseID) && (
                      <button  className="dashboard-btn" onClick={(e) => handleRemoveExercise(e,exercise)}>Delete</button>
                    )}
                  </div>
               </li>
               </ol>
              )}
              </Fragment>
            ))}
        </div>
      );
    });
  };
  
  
  /**
   * 
   * @param {*} workout is the workout object
   * @param {*} workoutIndex is the position in the workoutSchedule array
   * @param {*} applyToExercise is the position of the exercise in the workout
   * @returns 
   */
  const renderExerciseLogic = () => {
    let workoutIndexToAnalyze, workoutIDFromIndex;  
    if(logic && logic.exercisesAffected){
      // Find the exercise in the Exercises Affected Array
      const applyToExercise = logic.exercisesAffected.findIndex((ex)=>ex.exerciseID===selectedExercise.exerciseID);
      if (applyToExercise !== -1) {
      return (
        <div style={{ display: selectedExercise.exerciseID ? "block" : "none" }}>
        <hr></hr>
          <h3>Logic Editor</h3>
          <div className="row">
            <div className="col"><h4>Apply to Workout:</h4></div>
            <div className="col"><h4>{logic.applyToWorkoutScheduleIndex +1}</h4></div>
            <div className="col"><h4>{logic.exercisesAffected[applyToExercise].exerciseName}</h4></div>
          </div>
          <div> 
              <div>
              <label for="allOrAnyAnalyze">All/Any</label>
              <span>Select if all conditions, or any of the conditions must be met for consequences to apply</span>
                <select
                  id="allOrAnyAnalyze"
                  name="allOrAnyAnalyze"
                  defaultValue={logic.exercisesAffected[applyToExercise].allOrAnyAnalyze}
                  onChange={(event) => handleSelectChange(event, applyToExercise)}
                >
                  <option value="all">All</option>
                  <option value="any">Any</option>
              </select>
              <label for="aprevSetValSource">All/Any</label>
              <span>Select if conditions should be applied to the set values from the last exercise, or last workout. Chose Exercise
              if you need to use the most recent occurrence of an exercise that appears in multiple workouts.</span>
                <select
                  id="prevSetValSource"
                  name="prevSetValSource"
                  defaultValue={logic.exercisesAffected[applyToExercise].prevSetValSource}
                  onChange={(event) => handleSelectChange(event, applyToExercise)}
                >
                  <option value="last_exercise">Exercise</option>
                  <option value="last_workout">Workout</option>
              </select>
                {logic.exercisesAffected[applyToExercise] && logic.exercisesAffected[applyToExercise].analyze && logic.exercisesAffected[applyToExercise].analyze.length>0 ? logic.exercisesAffected[applyToExercise].analyze.map((analyzeBlock, analyzeBlockIndex) => 
                  <Fragment>
                  <div className="row">
                    <div className="col">
                      <h4>Workout to Analyze</h4>
                      <select
                        id="analyzeWorkoutIndex"
                        name="analyzeWorkoutScheduleIndex"
                        defaultValue={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].analyzeWorkoutScheduleIndex}
                        onChange={(event) => handleSelectChange(event, applyToExercise,analyzeBlockIndex)}
                      ><option value="">Select Workout</option> 
                      {/* Using slice to get all prior workouts in schedule */}
                        {programFields.workoutSchedule.slice(0, logic.applyToWorkoutScheduleIndex).map((workoutID, index) => {
                            const workout = programFields.workoutsInProgram.find((w) => w.workoutID === workoutID);
                              return (
                                <option key={workoutID} value={index}>
                                  {index+1} {workout.workoutName}
                                </option>
                              );
                            })}
                      </select>
                      </div>
                      <div className="col">
                 <h4>Exercise to Analyze</h4>
                          <select
                            id="analyzeWorkoutExercise"
                            name="analyzeWorkoutExercise"
                            value={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].exerciseID}
                            onChange={(event) => handleSelectChange(event, applyToExercise, analyzeBlockIndex)}
                          >
                            <option value="">Select Exercise</option>
                            {analyzedWorkout && analyzedWorkout.workoutExercises && analyzedWorkout.workoutExercises.map((exercise, index) => (
                              <option key={index} value={exercise.exerciseID}>
                                {exercise.exerciseName}
                              </option>
                            ))}
                          </select>
                          </div>
                          </div>
                          {/*All or Any Ifs*/}
                          <label htmlFor="allOrAnyIfs">All/Any</label>
                          <p>Select if all if conditions, or any of the if conditions must be met for consequences to apply</p>
                            <select
                              id="allOrAnyIfs"
                              name="allOrAnyIfs"
                              defaultValue={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].allOrAnyIfs}
                              onChange={(event) => handleSelectChange(event, applyToExercise,analyzeBlockIndex)}
                            >
                              <option value="all">All</option>
                              <option value="any">Any</option>
                          </select>
                      {analyzeBlock.ifs.map((ifBlock,ifIndex)=>{
                      return(                        
                         <Fragment>
                          {/* ifBlock.setsToAnalyze: [0,1,2,...] multiple choice checkbox filled with available data from selected exercise.*/}
                          <label htmlFor="setsToAnalyze">Sets To Analyze</label>
                          <select
                            multiple
                            name = "setsToAnalyze"
                            value={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].setsToAnalyze}
                            onChange={(event) => handleSelectChange(event, applyToExercise, analyzeBlockIndex, ifIndex)}
                          >
                            {analyzedWorkout.workoutExercises && analyzedWorkout.workoutExercises.map((exercise) => {
                              if (exercise.exerciseID === logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].exerciseID) {
                                return exercise.sets.map((set, index) => (
                                  <option key={index} value={index}>
                                    Set {index + 1} {set["set-type"]}
                                  </option>
                                ));
                              }
                              return null;
                            })}
                          </select>

                           <h4>Condition Set {ifIndex+1} </h4>                          
                           <button  className="dashboard-btn" onClick={(e) => handleRemoveAnalyzeIfBlock(e, applyToExercise, analyzeBlockIndex, ifIndex)}>Delete Condition</button>
                           <div className="row">
                            <div className="col">
                              <label htmlFor="analyzeIfBlock_target">Target</label>
                              <select
                                  id="analyzeIfBlock_target"
                                  name="target"
                                  defaultValue={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].target}
                                  onChange={(event) => handleSelectChange(event, applyToExercise,analyzeBlockIndex,ifIndex)}
                                >
                                  <option value="reps">reps</option>
                                  <option value="weight">weight</option>
                                  <option value="duration">duration</option>
                                  <option value="rest">rest</option>
                              </select>
                            </div>
                            <div className="col">
                            <label htmlFor="analyzeIfBlock_operator">Operator</label>
                           <select
                              id="analyzeIfBlock_operator"
                              name="operator"
                              defaultValue={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].operator}
                              onChange={(event) => handleSelectChange(event, applyToExercise,analyzeBlockIndex,ifIndex)}
                            >
                              <option value="<">{"<"}</option>
                              <option value="<=">{"<="}</option>
                              <option value="=">{"="}</option>
                              <option value=">=">{">="}</option>
                              <option value=">">{">"}</option>
                           </select>
                           </div>
                           <div className="col">
                           <label htmlFor="analyzeIfBlock_targetValue">Value</label>
                          {/* ifBlock.targetValue: 65, // input integer between 0-10000*/}
                          <input
                              id="analyzeIfBlock_targetValue"
                              name="targetValue"
                              type="number"
                              min={0}
                              max={100000}
                              value={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].targetValue}
                              onChange={(event) => handleSelectChange(event, applyToExercise,analyzeBlockIndex,ifIndex)}>
                          </input>
                          </div>
                          <div className="col">
                          <label htmlFor="analyzeIfBlock_targetUnit">Unit</label>
                          <select
                              id="analyzeIfBlock_targetUnit"
                              name="targetUnit"
                              defaultValue={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].targetUnit}
                              onChange={(event) => handleSelectChange(event, applyToExercise, analyzeBlockIndex, ifIndex)}
                            >
                              <option value="%">{"%"}</option>
                              <option value="unit">
                                {logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].target === "duration"
                                ||logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].target === "rest"
                                  ? "seconds"
                                  : logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].target === "weight"
                                  ? user.weightUnits
                                  : logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].target === "reps"
                                  ? "reps"
                                  :""}
                              </option>
                            </select>
                            </div>
                           {logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].targetUnit === "unit" ? null : (
                            <div className="col">
                                <label htmlFor="analyzeIfBlock_recordType">Record</label>
                              <select
                                  id="analyzeIfBlock_recordType"
                                  name="recordType"
                                  defaultValue={logic.exercisesAffected[applyToExercise].analyze[analyzeBlockIndex].ifs[ifIndex].recordType}
                                  onChange={(event) => handleSelectChange(event, applyToExercise,analyzeBlockIndex,ifIndex)}
                                >
                                  <option value="1RM">{"1RM"}</option>
                                  <option value="maxReps">{"maxReps"}</option>
                                  <option value="maxDuration">{"maxDuration"}</option>
                                  <option value="minRest">{"minRest"}</option>
                              </select>                          
                            </div>)}
                           </div>
                          </Fragment>)
                      })}
                      <button  className="dashboard-btn" onClick={(e) => handleAddAnalyzeIfBlock(e, applyToExercise, analyzeBlockIndex)}>Add Condition</button>
                  </Fragment>
                ):""}
                <button  className="dashboard-btn" onClick={(e) => handleAddAnalyze(e,applyToExercise)}>Add Analyze</button>
                 {logic && logic.exercisesAffected[applyToExercise] &&  logic.exercisesAffected[applyToExercise].analyze && logic.exercisesAffected[applyToExercise].analyze.length>0 && <button  className="dashboard-btn" onClick={(e) => handleRemoveAnalyze(e,applyToExercise)}>Remove Analyze</button>}
                <h4>Apply Consequences</h4>
                {logic.exercisesAffected[applyToExercise].apply && logic.exercisesAffected[applyToExercise].apply.then && logic.exercisesAffected[applyToExercise].apply.then.length>0 ? logic.exercisesAffected[applyToExercise].apply.then.map((thenBlock, thenBlockIndex) => {
                  let applyToWorkoutID = programFields.workoutSchedule[logic.applyToWorkoutScheduleIndex];
                  // Find Workout in Program
                  let applyToWorkout = programFields.workoutsInProgram.find((w)=>w.workoutID===applyToWorkoutID);
                  let specificExercise = applyToWorkout.workoutExercises.find((e)=>e.exerciseID===logic.exercisesAffected[applyToExercise].exerciseID);
                  return(
                  <Fragment>
                  <h3>Then Block {thenBlockIndex +1}</h3>
                  <button className="dashboard-btn" name="applyThenBlock" onClick={(e) => handleRemoveApply(e,applyToExercise,thenBlockIndex)}>Remove Then</button>
                  <label htmlFor="setsToApplyThen">Apply To Sets</label>
                  <select
                      multiple
                      name = "setsToApplyThen"
                      value={logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].setsToApply}
                      onChange={(event) => handleSelectChange(event, applyToExercise, thenBlockIndex)}
                    >
                      {specificExercise && specificExercise.sets && specificExercise.sets.map((set,index) => {
                        return(
                          <option key={index} value={index}>
                              Set {index + 1} {set["set-type"]}
                            </option>
                        )
                      })}
                  </select>
                  <div className="row">
                  <div className="col">
                    <label htmlFor="applyThenBlock_target">Target</label>
                    <select
                        id="applyThenBlock_target"
                        name="thenTarget"
                        defaultValue={logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].target}
                        onChange={(event) => handleSelectChange(event, applyToExercise,thenBlockIndex)}
                      >
                        <option value="reps">reps</option>
                        <option value="weight">weight</option>
                        <option value="duration">duration</option>
                        <option value="rest">rest</option>
                    </select>
                  </div>
                  <div className="col">
                  <label htmlFor="applyThenBlock_action">Action</label>
                  <select
                    id="applyThenBlock_action"
                    name="thenAction"
                    defaultValue={logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].action}
                    onChange={(event) => handleSelectChange(event, applyToExercise,thenBlockIndex)}
                  >
                    <option value="+">Add</option>
                    <option value="-">Subtract</option>
                    <option value="=">Equals</option>
                  </select>
                  </div>
                  <div className="col">
                  <label htmlFor="applyThenBlock_actionValue">Value</label>
                {/* ifBlock.targetValue: 65, // input integer between 0-10000*/}
                <input
                    id="applyThenBlock_actionValue"
                    name="thenActionValue"
                    type="number"
                    min={0}
                    max={100000}
                    value={logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].actionValue}
                    onChange={(event) => handleSelectChange(event, applyToExercise, thenBlockIndex)}>
                </input>
                </div>
                <div className="col">
                <label htmlFor="applyThenBlock_actionUnit">Unit</label>
                <select
                    id="applyThenBlock_actionUnit"
                    name="thenActionUnit"
                    defaultValue={logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].actionUnit}
                    onChange={(event) => handleSelectChange(event, applyToExercise, thenBlockIndex)}
                  >
                    <option value="%">{"%"}</option>
                    <option value="unit">
                      {logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].target === "duration"
                      ||logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].target === "rest"
                        ? "seconds"
                        : logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].target === "weight"
                        ? user.weightUnits
                        : logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].target === "reps"
                        ? "reps"
                        :""}
                    </option>
                  </select>
                  </div>
                  {logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].actionUnit === "unit" ? null : (
                  <div className="col">
                      <label htmlFor="applyThenBlock_recordType">Record</label>
                    <select
                        id="applyThenBlock_recordType"
                        name="thenRecordType"
                        defaultValue={logic.exercisesAffected[applyToExercise].apply.then[thenBlockIndex].recordType}
                        onChange={(event) => handleSelectChange(event, applyToExercise, thenBlockIndex)}
                      >
                        <option value="1RM">{"1RM"}</option>
                        <option value="maxReps">{"maxReps"}</option>
                        <option value="maxDuration">{"maxDuration"}</option>
                        <option value="minRest">{"minRest"}</option>
                    </select>                          
                  </div>)}
                  </div>
                  <button className="dashboard-btn" name="applyThenBlock" onClick={(e) => handleAddApply(e,applyToExercise)}>Add Then</button>
                  </Fragment>
                           
                )}):<Fragment>
                    <p>No Apply then blocks</p>                 
                    <button  className="dashboard-btn" name="applyThenBlock" onClick={(e) => handleAddApply(e,applyToExercise)}>Add Then</button>
                   </Fragment>}
  
                {logic.exercisesAffected[applyToExercise].apply && logic.exercisesAffected[applyToExercise].apply.else && logic.exercisesAffected[applyToExercise].apply.else.length>0 ? logic.exercisesAffected[applyToExercise].apply.else.map((elseBlock, elseBlockIndex) => {
                  let applyToWorkoutID = programFields.workoutSchedule[logic.applyToWorkoutScheduleIndex];
                  console.log(applyToWorkoutID);
                  // Find Workout in Program
                  let applyToWorkout = programFields.workoutsInProgram.find((w)=>w.workoutID===applyToWorkoutID);
                  console.log(applyToWorkout);
                  let specificExercise = applyToWorkout.workoutExercises.find((e)=>e.exerciseID===logic.exercisesAffected[applyToExercise].exerciseID);
                  console.log(specificExercise);
                  return(
                  <Fragment>
                  <h3>Else Block {elseBlockIndex +1}</h3>
                  {/* thenBlock.setsToApply: [0],// multiple choice checkbox filled with available data from selected exercise.*/}
                  <label htmlFor="setsToApplyElse">Apply To Sets</label>
                  <select
                      multiple
                      name = "setsToApplyElse"
                      value={logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].setsToApply}
                      onChange={(event) => handleSelectChange(event, applyToExercise, elseBlockIndex)}
                    >
                      {specificExercise && specificExercise.sets && specificExercise.sets.map((set,index) => {
                        return(
                          <option key={index} value={index}>
                              Set {index + 1} {set["set-type"]}
                            </option>
                        )
                      })}
                  </select>
                  <div className="row">
                  <div className="col">
                    <label htmlFor="applyElseBlock_target">Target</label>
                    <select
                        id="applyElseBlock_target"
                        name="elseTarget"
                        defaultValue={logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].target}
                        onChange={(event) => handleSelectChange(event, applyToExercise,elseBlockIndex)}
                      >
                        <option value="reps">reps</option>
                        <option value="weight">weight</option>
                        <option value="duration">duration</option>
                        <option value="rest">rest</option>
                    </select>
                  </div>
                  <div className="col">
                  <label htmlFor="applyElseBlock_action">Action</label>
                  <select
                    id="applyElseBlock_action"
                    name="elseAction"
                    defaultValue={logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].action}
                    onChange={(event) => handleSelectChange(event, applyToExercise,elseBlockIndex)}
                  >
                    <option value="+">Add</option>
                    <option value="-">Subtract</option>
                    <option value="=">Equals</option>
                  </select>
                  </div>
                  <div className="col">
                  <label htmlFor="applyElseBlock_actionValue">Value</label>
                {/* ifBlock.targetValue: 65, // input integer between 0-10000*/}
                <input
                    id="applyElseBlock_actionValue"
                    name="elseActionValue"
                    type="number"
                    min={0}
                    max={100000}
                    value={logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].actionValue}
                    onChange={(event) => handleSelectChange(event, applyToExercise, elseBlockIndex)}>
                </input>
                </div>
                <div className="col">
                <label htmlFor="applyElseBlock_actionUnit">Unit</label>
                <select
                    id="applyElseBlock_actionUnit"
                    name="elseActionUnit"
                    defaultValue={logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].actionUnit}
                    onChange={(event) => handleSelectChange(event, applyToExercise, elseBlockIndex)}
                  >
                    <option value="%">{"%"}</option>
                    <option value="unit">
                      {logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].target === "duration"
                      ||logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].target === "rest"
                        ? "seconds"
                        : logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].target === "weight"
                        ? user.weightUnits
                        : logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].target === "reps"
                        ? "reps"
                        :""}
                    </option>
                  </select>
                  </div>
                  {logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].actionUnit === "unit" ? null : (
                  <div className="col">
                      <label htmlFor="applyElseBlock_recordType">Record</label>
                    <select
                        id="applyElseBlock_recordType"
                        name="elseRecordType"
                        defaultValue={logic.exercisesAffected[applyToExercise].apply.else[elseBlockIndex].recordType}
                        onChange={(event) => handleSelectChange(event, applyToExercise, elseBlockIndex)}
                      >
                        <option value="1RM">{"1RM"}</option>
                        <option value="maxReps">{"maxReps"}</option>
                        <option value="maxDuration">{"maxDuration"}</option>
                        <option value="minRest">{"minRest"}</option>
                    </select>                          
                  </div>)}
                  </div>
                  <button  className="dashboard-btn" name="applyElseBlock" onClick={(e) => handleAddApply(e,applyToExercise)}>Add Else</button>
                  <button  className="dashboard-btn" name="applyElseBlock" onClick={(e) => handleRemoveApply(e,applyToExercise,elseBlockIndex)}>Remove Else</button>
                  </Fragment>
                           
                )}):<Fragment>
                    <p>No Apply else blocks</p>                 
                    <button  className="dashboard-btn" name="applyElseBlock" onClick={(e) => handleAddApply(e,applyToExercise)}>Add Else</button>
                   </Fragment>}
              </div>
          </div>
        </div> )
        }else{
          return null;
        }
    }
    
  };

  const handleWorkoutClick = (e,index) => {
    const {id} = e.target;
    // Right side
    if(id==="workoutSchedule"){
      if (expandedWorkoutIndex === index) {
        setExpandedWorkoutIndex(null);
      } else {
        setExpandedWorkoutIndex(index);
      }
      
    }
  };
  // End of helper functions ----------------------------------

  // Render Component -----------------------------------------
  return (
    <div className="Logiccontainer align-items-center justify-content-center">
      {/* Logic editor components */}
      <form id="logicEditorForm">
      <p>Click on a workout in the schedule to add/edit logic associated with an exercise. Logic cannot be added to the first workout.</p>
      {renderWorkouts()}
      {renderExerciseLogic()}
    </form>
  </div>
  );
};

export default ModifyLogic;
