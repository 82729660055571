import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

const ChartComponent = ({ workoutData, selectedExercise }) => {
  const [metric, setMetric] = useState('reps');

  // Preprocess the workout data to aggregate sets by counting occurrences
  const preprocessData = (data) => {
    const aggregatedData = data.reduce((acc, workout) => {
      if (workout.exercise === selectedExercise) {
        const key = workout.timestamp;
        if (!acc[key]) {
          acc[key] = { ...workout, sets: 0 };
        }
        acc[key].sets += 1;
      }
      return acc;
    }, {});
    return Object.values(aggregatedData);
  };

  const getChartData = () => {
    const filteredData = preprocessData(workoutData);
    const labels = filteredData.map(workout => new Date(workout.timestamp));
    const data = filteredData.map(workout => {
      switch (metric) {
        case 'reps':
          return workout.reps;
        case 'weight':
          return workout.weight;
        case 'volume':
          return workout.reps * workout.weight * workout.sets;
        default:
          return 0;
      }
    });

    return {
      labels,
      datasets: [
        {
          label: `${selectedExercise} - ${metric}`,
          data,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    };
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: metric.charAt(0).toUpperCase() + metric.slice(1),
        },
      },
    },
  };

  return (
    <div>
      <div>
        <button onClick={() => setMetric('reps')}>Reps</button>
        <button onClick={() => setMetric('weight')}>Weight</button>
        <button onClick={() => setMetric('volume')}>Volume</button>
      </div>
      <Line data={getChartData()} options={options} />
    </div>
  );
};

export default ChartComponent;