import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
import WorkoutHistory from "./WorkoutHistory.js"; 
// styling
import "./RecordList.css";
import "bootstrap/dist/css/bootstrap.css";
import { useOfflineFetch } from '../libs/offlineFetch';

// TODO 9-19-24: setup /current-program-stats route to display the current program stats
// for the exercise clicked on in the Records Table on Dashboard.
// Redesign so its not so ugly.

export default function Records({ program }) {
  console.log("In Records");
  // Display all Resistance Exercises
  // This will restrict records to the program level, and will not update among programs
  const {
    userPrograms,
    jwt,
    updateUserPrograms
  } = useContext(AppStateContext);
  const domain = "https://api.templatetrainer.com";

  // Allows User to set the records for each resistance exercise in the program.
  // Currently only displays resistance records
  function updateRecords(event, index) {
    const { name, value } = event.target;
    let programsCopy = JSON.parse(JSON.stringify(userPrograms));
    let programIndex = programsCopy.userPrograms.findIndex((p) => p.programID === program.programID);
    programsCopy.userPrograms[programIndex].records[index][name] = value;
    // Update Database and Context Api
    updateDbPrograms(programsCopy);
  }
  // Updates the Workout History in DB and Workout History in React Context Api
  const offlineFetch = useOfflineFetch();
   // Updates the Workout History in DB and Workout History in React Context Api
const updateDbPrograms = async (allPrograms) => {
  const url = domain+"/updatePrograms";
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`,
    },
    credentials: 'include', // Set this to true to include cookies in the request
    body: JSON.stringify(allPrograms),
  }

  try {
    const userWorkoutData = await offlineFetch(url, options, 'userPrograms', allPrograms);
    // Handle the response data as needed
  } catch (error) {
    console.log(error);
    // Handle error scenario, display an error message, etc.
    throw error;
  }
};

  const [editableRecordIndex, setEditableRecordIndex] = useState(null);

  const setEditableRecord = (index) => {
    setEditableRecordIndex(index);
  };

  const isRecordEditable = (index) => {
    return editableRecordIndex === index;
  };

  return (
    <div className="records-container">
      <div className="records-description">
        <p>Enter all record data you have. See program notes for required records for logic to work properly.
        Not all inputs may be required for the program.
        </p>
        <ul>
          <li> <strong>1RM</strong> = 1 Repetition Maximum (Powerlifting,Strength)</li>
          <li> <strong>Reps</strong> = Max reps performed (Calisthenics,Circuit).</li>
          <li> <strong>Duration (sec)</strong> = Max duration activity performed (Calisthenics,Circuit).</li>
          <li> <strong>Rest (sec)</strong> = Min time rested (Calisthenics,Circuit).</li>
        </ul>
      </div>
      {program.records && program.records.length > 0 ? (
        program.records.map((record, index) => {
          return (
            <Fragment key={index}>
              <div className={`record-row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                <div className="record-header">
                  <h4>{record.exerciseName}</h4>
                  <div className="record-buttons">
                    {isRecordEditable(index) ? (
                      <button className="dashboard-btn" onClick={() => setEditableRecord(null)}>Save</button>
                    ) : (
                      <button className="dashboard-btn" onClick={() => setEditableRecord(index)}>Edit</button>
                    )}
                  </div>
                </div>
                <div className="record-data">
                  <div className="record-field">
                    <label>1RM</label>
                    {isRecordEditable(index) ? (
                      <input
                        type="number"
                        min="0"
                        value={record["1RM"]}
                        name="1RM"
                        onChange={(event) => updateRecords(event, index)}
                      />
                    ) : (
                      <h4>{record["1RM"]} </h4>
                    )}
                  </div>
                  <div className="record-field">
                    <label>Reps</label>
                    {isRecordEditable(index) ? (
                      <input
                        type="number"
                        min="0"
                        value={record["maxReps"]}
                        name="maxReps"
                        onChange={(event) => updateRecords(event, index)}
                      />
                    ) : (
                      <h4>{record["maxReps"]} </h4>
                    )}
                  </div>
                  <div className="record-field">
                    <label>Duration</label>
                    {isRecordEditable(index) ? (
                      <input
                        type="number"
                        min="0"
                        value={record["maxDuration"]}
                        name="maxDuration"
                        onChange={(event) => updateRecords(event, index)}
                      />
                    ) : (
                      <h4>{record["maxDuration"]} </h4>
                    )}
                  </div>
                  <div className="record-field">
                    <label>Rest</label>
                    {isRecordEditable(index) ? (
                      <input
                        type="number"
                        min="0"
                        value={record["minRest"]}
                        name="minRest"
                        onChange={(event) => updateRecords(event, index)}
                      />
                    ) : (
                      <h4>{record["minRest"]} </h4>
                    )}
                  </div>
                </div>
              </div>
              <hr></hr>
            </Fragment>
          );
        })
      ) : (
        <p>No Records Set</p>
      )}
    </div>
  );
}
