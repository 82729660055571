import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
import WorkoutHistory from "./WorkoutHistory.js"; 
// styling
import "./Dashboard.css";
import "bootstrap/dist/css/bootstrap.css";
// For All Program Display
import CarouselDisplay from "./CarouselDisplay.js";
// For All Workout display
import WorkoutGrid from './WorkoutGrid';
import Card from "./Card";
import Slider from './Slider.js'
// Records
import Records from './RecordList';
import ExportRawData from "./ExportRawData";

// How to start video
import "./YoutubeEmbedStyles.css";
import YoutubeEmbed from "./YoutubeEmbed";

function Dashboard(props) {
  // All user data needed passed in from App after Login successful.
  // history is passed in by react router. keeps record of pages visited
  const [history] = useState(props.history);
  // Globale State from REACT CONTEXT API
  // Access the app state and update functions using useContext
  const {
    userPrograms,
    userWorkoutHistory,
    isAuthenticated
  } = useContext(AppStateContext);

  // Don't allow non-logged in users to access any pages other than login
  if (!isAuthenticated) {
    // redirect to login
    history.push("/");
  }
  // THIS COMPONENTS STATE
  const [workoutsInProgram, setWorkoutsInProgram] = useState(null);
  // Store Program object when clicked
  const [selectedProgram, updateSelectedProgram] = useState(null);
  const [chosenProgram,setChosenProgram] = useState("");
  const [chosenProgramRecords,setChosenProgramRecords] = useState("");
  // Store Workout object when clicked. Passed to Workout Component
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [currentWorkout, setCurrentWorkout]= useState({workoutScheduleIndex:"",workoutID:""});
  // Switch between displaying all programs and current program
  const [programDisplay, setProgramDisplay] = useState({
    currentProgram:true,
    allPrograms:false
  })
  const [lastProgram, setLastProgram] =useState(null);
  useEffect(() => {
    if (
      userPrograms.userPrograms &&
      userWorkoutHistory.userWorkoutHistory &&
      userWorkoutHistory.userWorkoutHistory.length > 0
    ) {
      // get last program from workout history
      let lastProgramFromHistory =userWorkoutHistory.userWorkoutHistory[userWorkoutHistory.userWorkoutHistory.length - 1];
      let foundLastProgram = userPrograms.userPrograms.find((p)=> p.programID === lastProgramFromHistory.programID);
      if(foundLastProgram){
        setLastProgram(foundLastProgram);
        updateSelectedProgram(foundLastProgram);
        displaySelectedProgram(foundLastProgram);
      }
      else if(userPrograms.userPrograms.length > 0){
        displayAllPrograms();
        setProgramDisplay({currentProgram: false, allPrograms: true });
      }else{
        displaySelectedProgram(null);
      }
    }
  }, [userPrograms.userPrograms, userWorkoutHistory.userWorkoutHistory]);

   // used to fix async state bug (stale state due to useState closure javascript issue. old state captured when program cards created in function)
  // stale state bug due to handleEditProgram lines 129 & 134 function capturing state prior to creation.
  const programRef = useRef(selectedProgram);
  useEffect(() => {
    programRef.current = selectedProgram;
    displayWorkouts();
    displaySelectedProgramRecords();
  }, [selectedProgram]);

  // Set the initial state of selected workout if there is a current workout
  useEffect(() => {
    if (currentWorkout && currentWorkout.workoutID) {
      setSelectedWorkout(currentWorkout);
    }
  }, []);

  // ALL HELPER FUNCTIONS
  //Display all programs
  const displayAllPrograms = () => {
    // Return a Carousel of all programs
    return <Slider slides={userPrograms.userPrograms} onClickHandler={displaySelectedProgram} lastProgram={lastProgram}/>
  };

  // Displays current program
  function displaySelectedProgram(program) {
    let programCopy = {...program};
    // Set the selected program
    updateSelectedProgram(programCopy);
    setProgramDisplay({currentProgram: true, allPrograms: false });
    // Check if programs. Then check if user currently running this program
    if (programCopy && userPrograms.userPrograms.length>0) {
      setChosenProgram(<Fragment>
        <Card
          key={`${programCopy.programName}~${programCopy.programID}`}
          imagen=""
          program={programCopy}
          selectProgram={displayWorkouts}
          buttonText=""
        /> 
        </Fragment>)
    }else{
      setChosenProgram(<div>
        <p>You currently have no workout programs.</p>
        <div id="createOrBuy-btns">
          <button className="dashboard-btn"
          onClick={() => history.push({
          pathname: "/programcreator",
          state: { "workout": '', "program": '' }
        })}
          >Create Programs</button>
          <button className="dashboard-btn">Buy Programs</button>
        </div>
      </div>);
    }
  }

  function displayWorkouts() {
    // Check if program exists and has workouts
    if (programRef.current  && programRef.current.workoutsInProgram && programRef.current.workoutsInProgram.length > 0) {
      // Check if there is a schedule
      if (programRef.current.workoutSchedule.length > 0) {
        // Check workout history for last workout performed in selected program
        let lastWorkoutArray = userWorkoutHistory.userWorkoutHistory.filter((p) => p.programID === programRef.current.programID);
        // Check if there was a last workout performed
        if (lastWorkoutArray[lastWorkoutArray.length - 1]) {
          // Grab next workout in workout schedule
          let lastWorkoutScheduleIndex = lastWorkoutArray[lastWorkoutArray.length - 1].workoutScheduleIndex;
          let currentWorkoutID = programRef.current.workoutSchedule[lastWorkoutScheduleIndex + 1];
  
          // Check if lastWorkoutScheduleIndex was the last workout in the schedule
          if (currentWorkoutID) {
            let currentWorkout = programRef.current.workoutsInProgram.find(({ workoutID }) => workoutID === currentWorkoutID);
            // May need to move this to useEffect
            setCurrentWorkout({workoutScheduleIndex:lastWorkoutScheduleIndex + 1,workoutID:currentWorkoutID});
            setWorkoutsInProgram(<WorkoutGrid key={programRef.current.programID} workouts={programRef.current.workoutsInProgram}  selectedProgram={programRef.current} currentWorkoutID={currentWorkoutID} onClickHandler={setSelectedWorkout}/>);
          } else {
            //Restart the program with the first workout
            setWorkoutsInProgram(<WorkoutGrid key={programRef.current.programID} workouts={programRef.current.workoutsInProgram}  selectedProgram={programRef.current} currentWorkoutID={programRef.current.workoutSchedule[0]} onClickHandler={setSelectedWorkout}/>);
          }
        } else {
          // No Workout History. Display first workout in Program's Workout Schedule
          console.log("No workout History");
          setCurrentWorkout({workoutScheduleIndex:0,workoutID:programRef.current.workoutSchedule[0]});
          setWorkoutsInProgram(<WorkoutGrid key={programRef.current.programID} workouts={programRef.current.workoutsInProgram}  selectedProgram={programRef.current} currentWorkoutID={programRef.current.workoutSchedule[0]} onClickHandler={setSelectedWorkout}/>);
        }
      } else {
        // Program doesn't have workout schedule or exercise logic. Display all workouts from the selected program
        console.log("No Workout Schedule (and thus no logic)");
        setWorkoutsInProgram(<WorkoutGrid key={programRef.current.programID} workouts={programRef.current.workoutsInProgram}  selectedProgram={programRef.current} currentWorkoutID={""} onClickHandler={setSelectedWorkout}/>);
      }
    } else {
      setWorkoutsInProgram("No Workouts. Create one using the Program Editor");
    }
  }
  

  function displaySelectedProgramRecords() {
    if (programRef.current && programRef.current.records) {
      setChosenProgramRecords(<Records program={programRef.current} />);
    }
    return null;
  }
  
  // RENDER THE COMPONENT ------------------------------------------------|

  return (
    <div className="dashboard-container">
    {programDisplay.currentProgram && (
        <div id="current-program" 
        className="current-program">
          {/* Content for displaying current program and a button to show all program carousel */}
          <h1>CURRENT PROGRAM</h1>
          {chosenProgram}
          {userPrograms && userPrograms.userPrograms && userPrograms.userPrograms.length>0 &&
           <button className="dashboard-btn" onClick={() => setProgramDisplay({currentProgram: false, allPrograms: true })}>All Programs</button>}
        </div>
      )}
      {programDisplay.allPrograms && (
        <div id="allPrograms" 
          style={{
            position: "relative",
            display: programDisplay.allPrograms ? "block" : "none",
            margin: "0 auto",
            maxWidth: "400px"
          }}>
          {/* Content for displaying all programs, which changes to current program view when a program is selected. */}
          <h1>ALL PROGRAMS</h1>
          {displayAllPrograms()}
        </div>
      )}
      <hr></hr>
      {programRef.current && programRef.current.programID !== undefined && programDisplay.currentProgram && (
      <div id="workouts-in-current-program">
      <h1>Workouts</h1>
      {programRef.current.workoutSchedule.length >0 && <span>{currentWorkout.workoutScheduleIndex +1} of {programRef.current.workoutSchedule.length}</span>}
        {workoutsInProgram}
        <br />
        <button 
        className="dashboard-btn"
        onClick={() => history.push({
          pathname: "/workout",
          state: { "workout": selectedWorkout, "program": programRef.current }
        })} 
        disabled={!selectedWorkout || !selectedWorkout.workoutID}> Start Workout</button>
      </div>
      )}
      <hr></hr>
      <div id="dashboard-stats" style={{ display:  programDisplay.currentProgram && programRef.current && programRef.current.programName ? "block" : "none" }}>
      <div id="records">
      <h1>RECORDS</h1>
        {chosenProgramRecords}
      </div>
      <div id="workoutHistory">
      <h1>WORKOUT HISTORY</h1>
        {programRef.current && programRef.current.programID !== undefined && userWorkoutHistory && <WorkoutHistory selectedProgram={programRef.current} history={props.history} />}
      </div>
      <div id="exportData">
      <h1>EXPORT DATA</h1>
      <p>Analyze your timeseries data
alongside your other health data
(Fitbit,Whoop,Oura,Cronometer)
to discover correlations and run 
experiments. See guide here</p>
{userWorkoutHistory && 
userWorkoutHistory.userWorkoutHistory && 
userWorkoutHistory.userWorkoutHistory.length> 0 ?
(programRef.current && programRef.current.programID !== undefined ? <ExportRawData inputJSON={userWorkoutHistory} selectedProgram={programRef.current} />:"No Selected Program")
: "No User Workout History"}
      </div>
      <div>
      <h1>How to Start</h1>
      <YoutubeEmbed embedId="cGU-FibCXVA" />
      </div>
    </div>
    </div>
  );
}
export default Dashboard;
