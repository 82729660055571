import { motion } from "framer-motion";
import React from "react";
import {useRef,useEffect,useState, useContext} from "react";
import Card from "./Card";
import "./Slider.css"


function Slider({ slides, onClickHandler,lastProgram }) {
  const [width,setWidth] = useState(0);
  const carousel = useRef();
  const lastProgramID = lastProgram && lastProgram.programID ? lastProgram.programID:null

// https://youtu.be/W0bEL93tt4k
  useEffect(() => {
    setWidth(carousel.current.scrollWidth-carousel.current.offsetWidth);
    console.log(carousel.current.scrollWidth-carousel.current.offsetWidth);
  }, [slides]);

  return (
    <motion.div 
    className="carousel" 
    ref={carousel}
    whileTap={{cursor:"grabbing"}}>
          <motion.div drag="x" 
          dragConstraints={{right:0, left:-width}}
          className="inner-carousel">
            {slides.map(slide =>{
              const slideClassName = slide.programID === lastProgramID ? 'last-program' : 'item';
              console.log(lastProgramID);
              return(
                <motion.div className={slideClassName}>
                  <Card
                    key={`${slide.programName}~${slide.programID}`}
                    imagen=""
                    program={slide}
                    selectProgram={onClickHandler}
                    buttonText={slideClassName==='last-program'?"Resume":"Select"}
                  /> 
                </motion.div>

                )
            })}
          </motion.div>
    </motion.div>
  );
}

export default Slider;
