import React, { useState, useContext, Fragment } from "react";
import "./WorkoutHistory.css"; // Import CSS file for styling
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
// Updates Database when Network available, Queue when offline. Updates Context API
import { useOfflineFetch } from '../libs/offlineFetch';

// Creates an accordion for the workout history dashboard display  
export default function WorkoutHistory(props) {
      // All user data needed passed in from App after Login successful.
  // history is passed in by react router. keeps record of pages visited
  const [history] = useState(props.history);
  // Globale State from REACT CONTEXT API
  // Access the app state and update functions using useContext
  const {
    userWorkoutHistory,
    jwt,
    updateUserWorkoutHistory
  } = useContext(AppStateContext);
  const [expandedItem, setExpandedItem] = useState(null);
  const domain = "https://api.templatetrainer.com";

  const toggleExpand = (index) => {
    if (expandedItem === index) {
      // Collapse the currently expanded item if clicked again
      setExpandedItem(null);
    } else {
      // Expand the clicked item
      setExpandedItem(index);
    }
  };

  // Delete workout from workout history in database and update context api. Only allow the most recent workout to be deleted so logic is still ok.
  function deleteWorkout(e,workoutTimestamp){
    e.preventDefault();
    let response = window.confirm("Delete This workout?");
    if (response) {
      const workoutHistoryCopy = { ...userWorkoutHistory };
      // Find workout in workout history based on timestamp
      let theWorkoutIndex = workoutHistoryCopy.userWorkoutHistory.findIndex((w)=> w.timestamp===workoutTimestamp);
      workoutHistoryCopy.userWorkoutHistory.splice(theWorkoutIndex,1);
      if(theWorkoutIndex !== -1){
        // Update Context API
        // updateUserWorkoutHistory(workoutHistoryCopy);
        // Update Database & Context API
        updateDbWorkouts(workoutHistoryCopy);
      }
    }
  }
  // Updates the Workout History in DB and Workout History in React Context Api
  const offlineFetch = useOfflineFetch();

  const  updateDbWorkouts = async (userWorkout) => {
    try {
      const url = domain+"/updateWorkoutHistory";
      const options = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`,
        },
        credentials: 'include', // Set this to true to include cookies in the request
        body: JSON.stringify(userWorkout),
      };
      const response = await offlineFetch(url, options,'userWorkoutHistory',userWorkout);
      const userWorkoutData = await response.json();
    } catch (error) {
      console.log(error);
      // Handle error scenario, display an error message, etc.
      throw error;
    }
  };


  return (
    <div className="list-container">
    <ul className="list-group">
      {props.selectedProgram!==undefined && userWorkoutHistory.userWorkoutHistory && 
      userWorkoutHistory.userWorkoutHistory.filter((p)=>p.programID===props.selectedProgram.programID).length>0? 
      (userWorkoutHistory.userWorkoutHistory.filter((p)=>p.programID===props.selectedProgram.programID)
      .sort((a, b) => b.timestamp - a.timestamp) // Sort by oldest workoutDate
      .map((workout, index) => (
        <li
          key={workout.timestamp}
          className={`list-group-item list-group-item-action ${
            expandedItem === index ? "expanded" : ""
          }`}
          onClick={() => toggleExpand(index)}
        >
          <div className="list-item-content">
            <div className="list-item-column">{new Date(workout.timestamp).toLocaleDateString()}</div>
            <div className="list-item-column">{workout.workoutName}</div>
            <div className="list-item-column">
              <button
                className="view-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpand(index);
                }}
              >
                View
              </button>
              {userWorkoutHistory.userWorkoutHistory.filter((p)=>p.programID===props.selectedProgram.programID).length===index+1 && 
                <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  id={workout.workoutID}
                  onClick={(e) => {
                  e.stopPropagation();
                  deleteWorkout(e,workout.timestamp);
                }}
                ></i>}
            </div>
          </div>
        {expandedItem === index && (
          <div className="expanded-content">
    {workout.workoutExercises.map((exercise, exerciseIndex) => (
      <div key={exercise.exerciseID}>
        <h4>{exercise.exerciseName}</h4>
          <strong>Notes:</strong> {exercise.notes}
        {exercise.typeSelected === "resistance" && (
          <Fragment>
          <div><strong>Exercise Type:</strong> {exercise.typeSelected}</div>
          <div className="row">
            <strong className="col history">Set:</strong>
            <strong className="col history">Reps:</strong>
            <strong className="col history">Weight: </strong>
            <strong className="col history">Duration: </strong>
            <strong className="col history">Rest: </strong>
            {/* <strong className="col history">Set-Type: </strong> */}
          </div>
            {exercise.sets.map((set, setIndex) => (
              <div key={setIndex} className="row">
                <div className="col history">{set["set-type"][0].toUpperCase()}</div>
                <div className="col history">{set.reps}</div>
                <div className="col history">{set.weight}</div>
                <div className="col history">{set.duration} sec</div>
                <div className="col history">{set.rest} sec</div>
               {/*  <div className="col history">{set["set-type"]}</div> */}
              </div>
            ))}
          </Fragment>
        )}
        {exercise.typeSelected === "circuit" && (
          <Fragment>
            <div>
              <strong>Rounds:</strong> {exercise.rounds}
            </div>
            <div>
              <strong>Exercises in Circuit:</strong>
              {exercise.exercisesInCircuit.map((subExercise, subExerciseIndex) => (
                <div key={subExercise.exerciseID} className="row">
                  <h5>{subExercise.exerciseName}</h5>
                  <div className="row">
                    <strong className="col history">Set:</strong>
                    <strong className="col history">Reps:</strong>
                    <strong className="col history">Weight: </strong>
                    <strong className="col history">Duration: </strong>
                    <strong className="col history">Rest: </strong>
                </div>
                    {subExercise.sets.map((set, setIndex) => (
                      <div key={setIndex} className="row">
                      <div className="col history">{setIndex + 1}</div>
                      <div className="col history">{set.reps}</div>
                      <div className="col history">{set.weight}</div>
                      <div className="col history">{set.duration} sec</div>
                      <div className="col history">{set.rest} sec</div>
                    </div>
                    ))}
                </div>
              ))}
            </div>
          </Fragment>
        )}
        <hr></hr>
      </div>
    ))} </div>
)}

        </li>
      ))):`No Workout History for ${props.selectedProgram!==undefined?props.selectedProgram.programName:"selected program"}`}
    </ul></div>
  );
}