import React, { Fragment, useState } from "react";
// Used to create multiple "pages", but really its a single page app
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// styling
import "bootstrap/dist/css/bootstrap.css";
import "./styles.css";
// ALL PAGES
import Workout from "./components/Workout.js";
import Login from "./components/LoginOrSignup.js";
import Dashboard from "./components/Dashboard.js";
import ChartPage from "./components/CurrentProgramStats.js";
import ProgramCreator from "./components/ProgramCreation.js";
import UserSettings from "./components/UserProfileSettings.js";
import ExerciseLibrary from "./components/ExerciseLibrary.js"

// Navigation
import NavbarComponent from "./components/Navbar.js";
// SAVE ALL STATE AND UPDATE FUNCTIONS IN REACT CONTEXT API
import { AppStateProvider } from './libs/AppStateContext.js';
// Import the GoogleAnalytics component
import GoogleAnalytics from './components/GoogleAnalytics';
/**
 *  ALL TODOS:
 *
 * https://developers.google.com/codelabs/pwa-training/pwa03--going-offline#0
 * https://dev.to/rdegges/please-stop-using-local-storage-1i04
 * 
 * Muscle SVGs: https://www.etsy.com/listing/1392223690/muscles-svg-bundle-editable-muscles?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=human+muscle+svg&ref=sr_gallery-1-1&sts=1&organic_search_click=1
 *
 */

export default function App() {
  return (
    <AppStateProvider>
      {/* Include the GoogleAnalytics component in head of every page*/}
      <GoogleAnalytics />
      <Router>
        <NavbarComponent/>
        <Switch>
          <Route
            path="/workout"
            render={(props) => (
              <Workout {...props}/>
            )} />
          <Route
            path="/programcreator"
            render={(props) => (
              <ProgramCreator {...props}/>
            )}
          />
          <Route 
            path="/usersettings"            
            render={(props) => (
              <UserSettings  {...props}/>
            )}
          />
          <Route
            path="/dashboard"
            render={(props) => (
              <Dashboard  {...props}/>
            )}
          />
          <Route
            path="/current-program-stats"
            render={(props) => (
              <ChartPage  {...props}/>
            )}
          />
          <Route
            path="/exercise-library"
            render={(props) => (
              <ExerciseLibrary {...props}/>
            )}
          />
          <Route
            exact
            path="/"
            render={(props) => (
              <Login {...props}/>
            )}
          />
        </Switch>
      </Router>
    </AppStateProvider>
  );
}
