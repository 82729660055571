import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import "./WorkoutGrid.css";
import { AppStateContext } from '../libs/AppStateContext';

const WorkoutGrid = ({ workouts, selectedProgram, onClickHandler, draggableCondition,currentWorkoutID }) => {
  const [height, setHeight] = useState(0);
  const workoutGridContainerRef = useRef();
  const workoutGridRef = useRef();
  console.log(currentWorkoutID)

  useEffect(() => {
    setHeight(workoutGridContainerRef.current.scrollHeight - workoutGridContainerRef.current.clientHeight);
    console.log(workoutGridContainerRef.current.scrollHeight - workoutGridContainerRef.current.clientHeight);
    console.log("rerendering workout grid");
  }, [workouts,selectedProgram]);

  return (
    <motion.div 
      className="workout-grid-container" 
      ref={workoutGridContainerRef}
      whileTap={{ cursor: "grabbing" }}
    >
      <motion.div 
        drag="y"
        dragConstraints={{ top: -height,bottom:0}}
        className="workout-grid"
      >
        {workouts.map((workout, index) => {
          const slideClassName = workout.workoutID === currentWorkoutID ? 'current-workout' : 'workoutGridItem';
          return (
            <motion.div className={slideClassName} key={index}>
              <div id={workout.workoutID} className="workout-tile"  onClick={() => onClickHandler(workout)}>
                <h3 className="workout-name">{workout.workoutName}</h3>
                <ul className="exercise-list ellipsis-text">
                  {workout.workoutExercises.map((exercise, exerciseIndex) => (
                    <li key={`${exercise.exerciseName}~${exercise.exerciseID}`} className="ellipsis-text">
                      {exercise.exerciseName}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          )
        })}
      </motion.div>
    </motion.div>
  );
}

export default WorkoutGrid;
