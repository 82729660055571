import React, { Fragment, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import CircuitTimer from "./CircuitTimer.js";

function CircuitExercise(props) {
  const { specificExercise, fullWorkout, updateWorkout } = props;

  function handleInputChange(event,index) {
   const {name, value} = event.target;
    if (isNaN(value) || value < 0) {
      alert("Only non-negative integers");
      return;
    }
    let copyOfSpecificExercise = {...specificExercise};
    copyOfSpecificExercise.exercisesInCircuit[index].sets[0][name]=parseInt(value, 10);
    const workoutCopy = { ...fullWorkout };
    let circuitIndex = workoutCopy.workoutExercises.findIndex((exercise)=>exercise.exerciseID===copyOfSpecificExercise.exerciseID)
    workoutCopy.workoutExercises[circuitIndex]=copyOfSpecificExercise;
    updateWorkout(workoutCopy);
  }

  const descriptionArray = specificExercise.exercisesInCircuit.map((exercise,index) => (
    <div className="resp-table-row" key={exercise.exerciseID}>
      <div className="table-body-cell">{exercise.exerciseName}</div>
      <div className="table-body-cell">
        <input
          id={`${exercise.exerciseID}-reps`}
          type="number"
          min="0"
          value={exercise.sets[0].reps}
          name="reps"
          onChange={(e)=>handleInputChange(e,index)}
        />
      </div>
      <div className="table-body-cell">
        <input
          id={`${exercise.exerciseID}-weight`}
          type="number"
          min="0"
          value={exercise.sets[0].weight}
          name="weight"
          onChange={(e)=>handleInputChange(e,index)}
        />
      </div>
      <div className="table-body-cell">
        <input
          id={`${exercise.exerciseID}-duration`}
          type="number"
          min="0"
          value={exercise.sets[0].duration}
          name="duration"
          onChange={(e)=>handleInputChange(e,index)}
        />
      </div>
      <div className="table-body-cell">
        <input
          id={`${exercise.exerciseID}-rest`}
          type="number"
          min="0"
          value={exercise.sets[0].rest}
          name="rest"
          onChange={(e)=>handleInputChange(e,index)}
        />
      </div>
    </div>
  ));
  

  return (
    <div>
      <div id="circuitTimerControls">
        <h1>{specificExercise.exerciseName}</h1>
        <CircuitTimer specificExercise={specificExercise} />
      </div>
      <hr />
      <div id="resp-table">
        <div id="resp-table-header">
          <div className="table-header-cell">EXERCISE</div>
          <div className="table-header-cell">REPS</div>
          <div className="table-header-cell">WEIGHT</div>
          <div className="table-header-cell">{window.innerWidth <= 500 ? 'DUR.' : 'DURATION'}</div>
          <div className="table-header-cell">REST</div>
        </div>
        <div id="resp-table-body">{descriptionArray}</div>
      </div>
      <hr className="dashed" />
    </div>
  );
}

export default CircuitExercise;
