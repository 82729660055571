import "./Card.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
// Generic Program Image for Men
import ProgramImage from "../assets/images/Jman_Program_Image.jpg"

function Card({ imagen ,program,selectProgram,buttonText}) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });

  return (
    <animated.div
      className="card"
      style={{
        ...props3,
        backgroundImage: `url(${ProgramImage})`,
        backgroundSize: "cover",
      }}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <h4>{program?program.programName:"No Name"}</h4>
      {buttonText && buttonText.trim() !== "" && ( // Check if buttonText is not empty
        <div className="btnn">
          <button
            style={{
              margin: "0 auto",
              backgroundColor: "transparent",
              color: "#fff",
              border: "none",
              textAlign: "center"
            }}
            id={program.programID}
            onClick={() => selectProgram(program)}
          >
            {buttonText}
          </button>
        </div>
      )}
      <div className="program-info">
        <span>Author: {program&&program.author?program.author:"No Name"}</span>
        <span>Categories:</span>
        <span>{program&&program.categories?program.categories.join(", "):"No Categories"}</span>
      </div>
    </animated.div>
  );
}

export default Card;

