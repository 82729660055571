import React, { useState, Fragment, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./WorkoutEditorForm.css"
// form components
import ResistanceFormEditor from "./ResistanceFormEditor.js"
import CircuitFormEditor from "./CircuitFormEditor.js"
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
import OrderableList from "./orderableList.js";
import { generateUniqueExerciseID } from "../libs/generateUniqueID";

function WorkoutForm(props) {
    // Globale State from REACT CONTEXT API
  // Access the app state and update functions using useContext
  const {
    user,
    userPrograms,
    userExercises,
    ttExercises,
    userWorkoutHistory,
    isAuthenticated,
    updateUser,
    updateUserPrograms,
    updateUserExercises,
    updateUserWorkoutHistory,
    updateAuth
  } = useContext(AppStateContext);
   // history is passed in by react router. keeps record of pages visited
   const [history] = useState(props.history);
   // Don't allow non-logged in users to access any pages other than login
   if (!isAuthenticated) {
     // redirect to login
     history.push("/");
   }

  // Used to update Program temporarily (state from Program Creation Component). Need to click save to take effect and update Global state and database.
  const [currentWorkoutIndex, setCurrentWorkoutIndex] = useState(null);
  useEffect(() => {
    const workoutIndex = props.programFields.workoutsInProgram.findIndex(
      ({ workoutID }) => workoutID === props.currentWorkout.workoutID
    );
    setCurrentWorkoutIndex(workoutIndex);
  }, [props.programFields, props.currentWorkout]);

  console.log("Program Fields Objs in workout editor")
  var prevSelectedExercise = "";
  // used to show certain divs
  const [pages, updatePages] = useState({
    "workoutEditor": false,
    "workoutName": false,
    "addExercise": false,
    "editExercise": false
  });
  const [selectedExercise, setSelectedExercise] = useState(null);
  // will be object with {name:value} for each select field on here.
  const [allSelectFieldsVals, updateAllSelectFieldVals] = useState({
    "exerciseEditor": "",
    "exerciseType": "",
    "exercise": ""
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredExercises, setFilteredExercises] = useState([]);

  //TODO: change this so it is not used to render the exercise editor unless selection is circuit
  function handleSelectChange(event) {
       // set value of select to object selected
       let copyOFObj = { ...allSelectFieldsVals };
       copyOFObj[event.target.id] = event.target.value;
       updateAllSelectFieldVals(copyOFObj);
    // deal with select one option
    if (event.target.value === "select" || event.target.value === "resistance") {
      return;
    }
    // Match ID to correct exercise to display correct exercise component
    // Adding New Exercise to Workout
      var exerciseStructure = "";
      if(event.target.value === "circuit" ){
        updatePages({
          "workoutEditor": true,
          "workoutName": false,
          "addExercise": false,
          "editExercise": false
        })
        exerciseStructure = {
          "exerciseName": "Circuit",
          "exerciseID": generateUniqueExerciseID("exercise", [...userExercises.userExercises,
            ...ttExercises.TemplateTrainerExercises]),
          "typeSelected": "circuit",
          "rounds": 0,
          "notes": "",
          "exercisesInCircuit": []
        }
      }
        let programCopy = { ...props.programFields };
        programCopy.workoutsInProgram[currentWorkoutIndex].workoutExercises.push(exerciseStructure);
        console.log("modified workout in select change")
        console.log(programCopy.workoutsInProgram[currentWorkoutIndex].workoutExercises);
        props.updateProgramFields(programCopy);
        // clear select vals 
        copyOFObj["exercise"] = "";
        copyOFObj["exerciseType"] = "";
        //modify the workout holder
        updatePages({ ...pages, addExercise: !pages.addExercise });
        setSelectedExercise(exerciseStructure);
    }
  
  // UPDATES NAME OF CURRENT WORKOUT
  function handleworkoutNameChange(event) {
    // find workout in program
    let programObj = { ...props.programFields };
    if(currentWorkoutIndex !== -1){
      // update the name property, assign a new value
      programObj.workoutsInProgram[currentWorkoutIndex].workoutName =  event.target.value;
      props.updateProgramFields(programObj);
    }
  }

  // Search for an exercise to add to the workout ----------------------------
  const handleSearch = (e) => {
    setSelectedExercise(null);
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  
    if (searchTerm.trim().toLowerCase() === '') {
      setFilteredExercises([]);
      return;
    }
    
    const combinedExercises = [...ttExercises.TemplateTrainerExercises, ...userExercises.userExercises];
    const filteredExercises = combinedExercises.filter(exercise => {
      const keysToSearch = ["musclesInvolved", "equipment", "exerciseName"];
      for (let key in exercise) {
        if (keysToSearch.includes(key)) {
          const exerciseValue = exercise[key];
          if (typeof exerciseValue === 'string' && exerciseValue.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }
          if (Array.isArray(exerciseValue) && exerciseValue.some(value => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase()))) {
            return true;
          }
        }
      }
      return false;
    });
    setFilteredExercises(filteredExercises);
  };

  const handleExerciseClick = (exercise) => {
    setSearchTerm("");
    setFilteredExercises([]);
    // Update the temporary workout
    let exerciseStructure = {
      "exerciseName": exercise.exerciseName,
      "exerciseID": exercise.exerciseID,
      "typeSelected": "resistance",
      "sets": [
        {
          "reps": 0,
          "weight": 0,
          "duration": 0,
          "rest": 0,
          "set-type": "standard"
        },
      ],
      "notes": "",
    };
    let programCopy = { ...props.programFields };
    programCopy.workoutsInProgram[currentWorkoutIndex].workoutExercises.push(exerciseStructure);
    console.log("modified workout in exercise click")
    // Create a record holder in Program Records
    let recordExists =  programCopy.records.find((record)=>record.exerciseID===exercise.exerciseID);
    if (recordExists === undefined){
      programCopy.records.push({"exerciseName": exercise.exerciseName,"exerciseID": exercise.exerciseID,"1RM": 0,"maxReps": 0,"maxDuration": 0,"minRest":0})
      console.log("Adding Records!")
      console.log(programCopy);
    }
    console.log(programCopy);
    props.updateProgramFields(programCopy);
    updatePages({ ...pages, addExercise: !pages.addExercise });
    setSelectedExercise(exerciseStructure);
  };

  const updateListDelete= (newList,exerciseID) => {
    let programCopy = { ...props.programFields };
    let record = programCopy.records.findIndex((ex)=>ex.exerciseID===exerciseID);
    let deletedExercise = programCopy.workoutsInProgram[currentWorkoutIndex].workoutExercises.find((exercise)=>exercise.exerciseID===exerciseID);
    // Iterate through the workoutExercises array of each program's workout
    let count = 0;
    programCopy.workoutsInProgram.forEach((workout) => {
      workout.workoutExercises.forEach((exercise) => {
        if (exercise.exerciseID === exerciseID) {
          count++;
        }
      });
    });
    // if type is resistance and it only occurs once in the entire program, remove from records
    if(deletedExercise && deletedExercise.typeSelected=== "resistance" && count===1 && record !==-1){
      programCopy.records.splice(record,1);
    }
    programCopy.workoutsInProgram[currentWorkoutIndex].workoutExercises = newList;
    props.updateProgramFields(programCopy);
  }
  // END Search for an exercise to add to the workout ----------------------------

  // Open the correct editor when an exercise is clicked on:
  const handleItemClick = (index) => {
    console.log("List item clicked:", index);
    const selectedExercise = props.programFields.workoutsInProgram[currentWorkoutIndex].workoutExercises[index];
    setSelectedExercise(selectedExercise);
  };
// This is used to update the exercise editor component display when the exercise is changed.
  useEffect(() => {
    createComponent();
  }, [selectedExercise]);

  function createComponent() {
    if (selectedExercise && selectedExercise.typeSelected) {
      switch (selectedExercise.typeSelected) {
        case "resistance":
          return prevSelectedExercise !== selectedExercise ? (
            <ResistanceFormEditor
              key={selectedExercise.exerciseID}
              selectedExercise={selectedExercise}
              currentWorkoutIndex={currentWorkoutIndex}
              programFields={props.programFields}
              updateProgramFields={props.updateProgramFields}
            />
          ) : null;
        case "circuit":
          return (
            <CircuitFormEditor
              currentWorkoutIndex={currentWorkoutIndex}
              selectedExercise={selectedExercise}
              programFields={props.programFields}
              updateProgramFields={props.updateProgramFields}
            />
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  return (
    <div id="container">
      <div id="workoutName">
        <div>
          <h2>Workout: {props.programFields && props.programFields.workoutsInProgram && currentWorkoutIndex !== null
             ? props.programFields.workoutsInProgram[currentWorkoutIndex].workoutName : "No Name, Something went wrong. Call Batman"}
             <button className="dashboard-btn" style={{ margin: "20px", fontSize: "12px" }}>
            <i id="edit-workout-name"
              className="fa fa-pencil"
              aria-hidden="true"
              onClick={(event) => updatePages(Object.assign({ ...pages }, { "workoutName": !pages.workoutName }))}>
            </i>
            </button>
          </h2>
        </div>
        <div id="workoutNameInput" style={{ display: pages.workoutName ? "block" : "none" }}>
          <input
            type="text"
            required
            className="workoutName"
            id="workoutName"
            name="workoutName"
            value={currentWorkoutIndex !== null && props.programFields.workoutsInProgram[currentWorkoutIndex].workoutName}
            onChange={(event) => handleworkoutNameChange(event)}
          />
        </div>
        <hr />
      </div>
      <div id="workoutEditor">
      <h2>Exercises In Workout</h2>
      <p>Click to expand and edit. Exercises can be reordered in workout by dragging.</p>
      <button
      className="dashboard-btn"
          onClick={() => updatePages({
            "workoutEditor": false,
            "workoutName": false,
            "addExercise": true,
            "editExercise": false
          })}>
          Add Exercise
        </button>
        <div id="addExercise"
        style={{ display: pages.addExercise ? "block" : "none" }}>
        <label>
          Select Exercise Type:
        </label>
        <select id="exerciseType"
          name="exerciseType"
          value={allSelectFieldsVals.exerciseType}
          onChange={(event) => handleSelectChange(event)}>
          <option id="select" value="select">Select One</option>
          <option id="resistance" value="resistance">Resistance</option>
          <option id="circuit" value="circuit">Circuit</option>
        </select>
        <div id="exerciseSearch" style={{ display: allSelectFieldsVals.exerciseType === "resistance" ? "block" : "none" }}>
          <input
              type="text"
              placeholder="Search exercises"
              value={searchTerm}
              onChange={handleSearch}
            />
            <ul className="list-group">
              {filteredExercises.map(exercise => (
                <li key={exercise.exerciseID} className="list-group-item list-group-item-action" onClick={() => handleExerciseClick(exercise)}>
                <a href="#" style={{ textDecoration: "none", color:"#A10404" }}> {exercise.exerciseName}</a>
                </li>
              ))}
            </ul>
        </div>
      </div>
        {currentWorkoutIndex !== null && props.programFields.workoutsInProgram[currentWorkoutIndex].workoutExercises.length> 0 && <OrderableList list={currentWorkoutIndex !== null && props.programFields.workoutsInProgram[currentWorkoutIndex].workoutExercises} updateList={updateListDelete} onItemClick={handleItemClick} />}
        <hr />
        <div id="editExercise"
          style={{ display: selectedExercise ? "block" : "none" }}>
          {selectedExercise ? createComponent() : "Something Went Wrong"}
        </div>
      </div>
    </div>
  );
}

export default WorkoutForm;
