import React, { useState, useCallback } from "react";
import "./workoutSchedule.css"

const WorkoutScheduler = ({ programFields, updateProgramFields }) => {
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const [workoutsPerWeek, setWorkoutsPerWeek] = useState(6); // Default value

  const updateGridColumns = () => {
    const gridContainer = document.querySelector('.grid-container');
    if (workoutsPerWeek > 0 && workoutsPerWeek <= 7) {
      gridContainer.style.gridTemplateColumns = `repeat(${workoutsPerWeek}, 1fr)`;
    } else {
      alert('Please enter a valid number of workouts per week.');
    }
  };

  const handleDragStart = (e, index, sourceList) => {
    e.dataTransfer.setData("text/plain", JSON.stringify({ index, sourceList }));
    setDraggedIndex(index); // Save the dragged item index in state
    // Retrieve the current position of the dragged item
    const { clientX, clientY } = e;
    console.log("Current Position:", clientX, clientY);
    const item = e.target;
    setTimeout(() => {
      item.classList.add('dragging');
    }, 0);
  };

  const handleDragOver = useCallback((e, index) => {
    // useCallback is used to stop excessive rerenders.
    e.preventDefault();
    // Used to add movement effect
    setDraggedOverIndex(index);
  }, []); // No dependencies, so the function will be memoized

  const handleDrop = (e, destinationIndex) => {
    e.stopPropagation();
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    const { index, sourceList } = data;
    let programCopy = {...programFields};
    let newSchedule = [...programCopy.workoutSchedule];
    let draggedWorkout = "";
    if (sourceList === "workoutList") {
      // Item is from the workout list, perform handleDrop action
      draggedWorkout = programFields.workoutsInProgram[index].workoutID;
      // Deal with adding workout to end of array
      if (newSchedule.length === destinationIndex+1 ){
        destinationIndex+=1;
      }
    } else if (sourceList === "scheduleList") {
      // Item is from the schedule list, perform reordering action
      draggedWorkout = programFields.workoutSchedule[index];
      // Remove the workout from the source index
      newSchedule.splice(index, 1);
    }
    newSchedule.splice(destinationIndex, 0, draggedWorkout);
    // update workoutSchedule
    programCopy.workoutSchedule = newSchedule;
    //update scheduleIndex 
    updateProgramFields(programCopy);
    setDraggedIndex(null);
    setDraggedOverIndex(null);
  };

  const handleDragEnd = (e) => {
    const item = e.target;
    item.classList.remove('dragging');
    setDraggedIndex(null); // Reset the dragged item index
  };
  
  const handleDelete = (e,index) => {
    let programCopy = {...programFields};
    let newSchedule = [...programCopy.workoutSchedule];
    // Delete from workoutSchedule
    newSchedule.splice(index, 1);
    programCopy.workoutSchedule = newSchedule;
    updateProgramFields(programCopy);
  };

  if (!programFields || !programFields.workoutSchedule || !programFields.workoutsInProgram) {
    return <div>Loading...</div>; // or any loading indicator
  }

  return (
    <div id="target" onDragOver={programFields.workoutSchedule.length === 0 ? (e) => handleDragOver(e, 0) : null} onDrop={programFields.workoutSchedule.length === 0 ? (e) => handleDrop(e, 0) : null}
      style={{
        maxWidth: "800px",    
        maxHeight: "250px",
        overflow: "hidden",
        touchAction: "pan-y",
        overflowY: "auto",
        margin: "0 auto",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        background: draggedOverIndex === 0 && programFields.workoutSchedule.length === 0 ? "#A10404" : "white",
      }}
      >
      <h2>Workout Schedule</h2>
      <input
        type="number"
        value={workoutsPerWeek}
        onChange={(e) => setWorkoutsPerWeek(e.target.value)}
        placeholder="Enter number of workouts per week"
        min="1"
        max="7"
      />
      <button onClick={updateGridColumns}>Update Schedule</button>
      <ol className="grid-container"  start={1}>
        {programFields.workoutSchedule.length>0 && programFields.workoutSchedule.map((workout, index) => {
          const isDragged = index === draggedIndex;
          const isDraggedOver = index === draggedOverIndex;
          const itemStyles = {
              transform: isDraggedOver ? "translateY(10px)" : "none",
              margin:"2px"
            };
          return (
            <li
              key={index}
              className="item"
              draggable
              onDragStart={(e) => handleDragStart(e, index, "scheduleList")}
              onDragOver={(e) => handleDragOver(e, index) }
              onDrop={(e) => handleDrop(e, index)}
              onDragEnd={handleDragEnd}
              style={itemStyles}
            >
              <div className="details">
                <span className="number">{index + 1}.</span>
                <span className="workout-name">
                  {programFields.workoutsInProgram.find( (someWorkout) => someWorkout.workoutID === workout).workoutName}
                </span>
                <i
                  id = {workout}
                  className="fa fa-trash"
                  aria-hidden="true"
                  onClick={(e) => handleDelete(e,index)}
                ></i>
                <i
                  className={`logic-icon ${logicEditorVisible ? 'active' : ''}`}
                  onClick={() => toggleLogicEditor(workout)}
                >
                  L
                </i>
                
              </div>
            </li>
            );
          })}
      </ol>
    </div>
  );
};

export default WorkoutScheduler;
