import React, { useState, useEffect, useContext } from "react";
import RestTimer from "./RestTimer.js";
import { AppStateContext } from "../libs/AppStateContext";

/*TODO: Deal with null vals in sets reps weights
 * TODO: add everything into useEffect?
 * https://stackoverflow.com/questions/37998501/return-zero-if-input-value-is-nan
 */

function ResistanceExercise(props) {
  console.log("rendering Resistance Exercise component")
  console.log(props);
  // timer and specificSet are for rest timer
  const [timer,showTimer] = useState(false);
  // each set may have different rest time
  const [specificSet,updateSpecificSet] = useState(0);

  // Event Handler Functions
  function startRestTimer(event){
    // Only run when checkbox is checked.
    if(event.target.checked){
      showTimer(true);
      updateSpecificSet(event.target.id);
    }
    else{
      showTimer(false);
    }
  }

function handleInputChange(event, exercise) {
  const { id, name, value, type, checked } = event.target;
  const setIndex = parseInt(id, 10);
  
  // Create a deep copy of the full workout object
  const workoutCopy = JSON.parse(JSON.stringify(props.fullWorkout));
  
  // Find the exercise in the workoutCopy
  const exerciseIndex = workoutCopy.workoutExercises.findIndex(
    ({ exerciseID }) => exerciseID === exercise
  );
  
  if (exerciseIndex !== -1) {
    const setCopy = JSON.parse(JSON.stringify(workoutCopy.workoutExercises[exerciseIndex]));
    
    if (type === "checkbox") {
      setCopy.sets[setIndex][name] = checked;
    } else if (type === "number") {
      setCopy.sets[setIndex][name] = parseInt(value, 10);
    } else if (type === "text") {
      setCopy.notes = value;
    }
    
    workoutCopy.workoutExercises[exerciseIndex] = setCopy;
    
    props.updateWorkout(workoutCopy);
  }
}


  const descriptionArray = props.specificExercise.sets.map((set, index) => (
    <div className="resp-table-row" key={index}>
      <div className="table-body-cell">{set["set-type"][0].toUpperCase()}</div>
      <div className="table-body-cell">
        <input
          type="number"
          id={index}
          min="0"
          value={set.reps}
          name="reps"
          onChange={(event) => handleInputChange(event, props.specificExercise.exerciseID)}
      />
      </div>
      <div className="table-body-cell">
        <input
          id={index}
          type="number"
          min="0"
          value={set.weight}
          name="weight"
          onChange={(event) => handleInputChange(event, props.specificExercise.exerciseID)}
      />
      </div>
      <div className="table-body-cell">
        <input
          id={index}
          type="number"
          min="0"
          value={set.duration}
          name="duration"
          onChange={(event) => handleInputChange(event, props.specificExercise.exerciseID)}
      />
      </div>
      <div className="table-body-cell">
        <input
          id={index}
          type="checkbox"
          name="rest"
          onChange={startRestTimer}
        />
      </div>
    </div>
  ));
  

  return (
    <div id="resistance-container" style={{ position: 'relative' }}> {/* Ensure the parent container has position: relative */}
      <h1>{props.specificExercise.exerciseName}</h1>
      {/* Add notes to Workout History JSON, not Workout Program JSON used here*/}
      <h3 name="notes">
        Notes
      </h3>
      <div className="table-body-cell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <input
          id={props.specificExercise.exerciseID}
          type="text"
          value={props.specificExercise.notes}
          name="notes"
          onChange={(event) => handleInputChange(event, props.specificExercise.exerciseID)}
      />
      </div>
      <div style={{ position: 'sticky', top: 0 }}>  {/* Apply sticky positioning to this div */}
      {timer?<RestTimer specificExercise={props.specificExercise} specificSet={specificSet} showTimer={showTimer}/>:""}</div>
      <hr></hr>
      <div id="resp-table">
        <div id="resp-table-header">
          <div className="table-header-cell">SET</div>
          <div className="table-header-cell">REPS</div>
          <div className="table-header-cell">WEIGHT</div>
          <div className="table-header-cell">{window.innerWidth <= 500 ? 'DUR.' : 'DURATION'}</div>
          <div className="table-header-cell">REST</div>
        </div>
        <div id="resp-table-body">
          {descriptionArray}
        </div>
      </div>
      <hr className="dashed"></hr>
    </div>
  );
}

export default ResistanceExercise;
