import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
// MY CUSTOM HOOK FOR ALL FORMS
import { useFormFields } from "../libs/hooksLib";
// REACT CONTEXT
import { AppStateContext } from '../libs/AppStateContext';
// CSS
import "./ProgramCreation.css";
// Form Templates
import WorkoutForm from "./WorkoutEditorForm.js";
import ModifyLogic from "./LogicEditorNew.js";

// Custom Components
import { generateUniqueExerciseID } from "../libs/generateUniqueID";
import Slider from './Slider.js'
import WorkoutScheduler from "./workoutSchedule";
// For All Workout display
import WorkoutGrid from './WorkoutGrid';
// File Upload Component
//import FileUploader from './FileUploader.js'
// Allow sharing of programs
import ShareModal from "./ShareProgram.js";

import { useOfflineFetch } from '../libs/offlineFetch';

/** IMPORTANT!! EDITING PROGRAM CAN BREAK WORKOUT HISTORY COMPATIBILITY IF SETS OR STRUCTURE ALTERED.
 * 
 * @param {*} props 
 * @returns 
 */

function ProgramCreator(props) {
  // from REACT CONTEXT API
  const {
    user,
    userPrograms,
    isAuthenticated,
    jwt,
    updateUserPrograms,
    pendingRequests,
    updatePendingRequests
  } = useContext(AppStateContext);
  console.log("In Program Creator");
  const [history] = useState(props.history);
  const domain = "https://api.templatetrainer.com";

  // used to display correct section on page
  const [editorPage, updateEditorPage] = useState(
    {
      "workouts": false,
      "programName": false,
      "workoutEditor": false,
      "logic": false,
      "programs": true,
      "name": false,
      "mainButtons": false,
      "exerciseEditor": false,
      "newExercise": false
    }
  );

  const [currentWorkout, setCurrentWorkout] = useState("");
  // selected program
  const [programFields, updateProgramFields] = useState(null);

  // Doesn't ever change. Gets passed into create new program
  /* Program Object needs all these keys
  {
    "userID": "",
    "userPrograms": [
      {
        "programID": "",
        "programName": "",
        "records": [{"exerciseName": "","exerciseID": "","1RM": 0,"maxReps": 0,"maxDuration": 0,"minRest":0}]
        ,"description":"",
        "categories":[],
        "workoutsInProgram": [
          {
            "workoutID": "",
            "workoutName": "",
            "workoutExercises": [
              {
                "exerciseName": "",
                "exerciseID": "",
                "typeSelected": "",
                "sets": [
                  {
                    "reps": 0,
                    "weight": 0,
                    "duration": 0,
                    "rest": 0,
                    "set-type": "standard"
                  }
                ],
                "notes": "Some Notes"
              }
            ]
          }
        ],
        "workoutSchedule":["Workout 1","Workout 2","Workout 3"],
        "exerciseLogic": [
           {"applyToWorkoutScheduleIndex":0,
          "exercisesAffected":[{	  
            "exerciseName": "",
            "exerciseID": "",
            "allOrAnyAnalyze":"all/any",
            "anaylze": [{
                    "analyzeWorkoutScheduleIndex":0,"exerciseName": "",
                    "exerciseID":"","allOrAnyIfs":"all/any",
                    "ifs": [{ "setsToAnalyze": [0,1],"target":"reps,weight,duration,rest", "operators":"</=/<=/=/>=/>", 
                        "targetValue":0, "targetUnit":"unit,%","recordType":"1RM,maxReps,maxDuration,minRest,none"}],
                       }],
            "apply": {
              "then":[{ "setsToApply": [0,1],"target":"reps,weight,duration,rest", "action":"-/+", 
                  "actionValue":5, "actionUnit":"unit,%","recordType":"1RM"}],
              "else":[{ "setsToApply": [0,1],"target":"weight", "action":"-/+", 
                  "actionValue":5, "actionUnit":"unit,%","recordType":"1RM"}]
                 }
           }]
           }
         ]
      }
    ]
  });
  */
  const newProgramFields = {
    programID: "",
    programName: "",
    records: [],
    description:"",
    categories:[],
    programImage:"",
    author:"",
    workoutsInProgram: [],
    workoutSchedule:[],
    exerciseLogic: []
  };

  // Workouts in selected program
  const [workoutCards, setWorkoutCards] = useState("");
  // Don't allow non-logged in users to access any pages other than login
  if (!isAuthenticated) {
    // redirect to login
    history.push("/");
  }

  // used to fix async state bug (stale state due to useState closure javascript issue. old state captured when program cards created in function)
  // stale state bug due to handleEditProgram lines 129 & 134 function capturing state prior to creation.
  const programRef = useRef(programFields);
  useEffect(() => {
    programRef.current = programFields;
  }, [programFields]);

  // Check for shared programs from other users.
  // TODO: create a notification area in Navbar with pending notices
  useEffect(() => {
    if (pendingRequests) {
      console.log("There are programs that were shared with you");
      for (const request of pendingRequests) {
        const response = window.confirm(`Add ${request.programName} from ${request.sendersEmail}?`);
        
        if (response) {
          const allPrograms = { ...userPrograms };
          allPrograms.userPrograms.push(request.program);
          
          // Update global app state in Context API
          updateUserPrograms(allPrograms);
          
          // Update Workout History in Database
          updateDbPrograms(allPrograms)
            .then(() => {
              console.log('Program added to userPrograms and updated in the database.');
            })
            .catch(error => {
              console.error('Error updating database:', error);
            });
          //Delete the pending request
          deleteShareProgramRequest(request.programID,request.recipientEmail);
          updatePendingRequests(null);
        }
      }
    } else {
      console.log("No user programs shared.");
    }
  }, [pendingRequests]);
  

  // ALL HELPER FUNCTIONS
  // Updates the Workout History in DB and Workout History in React Context Api
  const offlineFetch = useOfflineFetch();

  const deleteShareProgramRequest = async (programID, recipientEmail)  => {
    const url = domain + '/deleteShareProgramRequest';
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${jwt}`
      },
      credentials: 'include', 
      body: JSON.stringify({ programID, recipientEmail }),
    }
  try {
    const response = await offlineFetch(url, options, 'pendingRequests', { programID, recipientEmail });

    if (response.ok) {
      const data = await response.json();
      console.log(data.message); // Log success message
    } else {
      const errorData = await response.json();
      console.error('Error deleting program request:', errorData.message);
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

  function createWorkoutCards(workoutsInProgram){

    if(workoutsInProgram){
      setWorkoutCards(
        workoutsInProgram.map((workout, index) => (
            <div
                key={index}
                draggable
                onDragStart={(e) => handleDragStart(e, index, "workoutList")}
              id={workout.workoutID}
              className="workout-card"
            >
              <span>{workout.workoutName}</span>
              <div className="exercise-list-container">
              <ul className="exercise-list">
                    {workout.workoutExercises.map((exercise, exerciseIndex) => (
                      <li key={`${exercise.exerciseName}~${exercise.exerciseID}`}>
                        {exercise.exerciseName}
                      </li>
                    ))}
                  </ul>
              </div>
              <div className="btn-container">
                <button className="dashboard-btn"
                  id={workout.workoutID}
                  onClick={(event) => handleEditWorkout(event)}>
                  Edit
                </button>
                <button className="btn">
                  <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  id={workout.workoutID}
                  onClick={(event) => deleteWorkout(event)}
                  />
                </button>
              </div>
            </div>
        ))
      );
    }
  }

  // For draggable workout cards
  const handleDragStart = (e, index, sourceList) => {
    e.dataTransfer.setData("text/plain", JSON.stringify({ index, sourceList }));
  };

  // UPDATES NAME OF CURRENT Program
  function handleProgramNameChange(event) {
    let programObj = Object.assign({}, programFields); // creating copy of state variable workoutName
    programObj.programName = event.target.value; // update the name property, assign a new value
    updateProgramFields(programObj);
  }

  //TODO 7-12-23 - create categories, create program description, create upload field for program Image.
  function editDescription(event){
    //copyOfProgram = {...programRef.current }
    updateProgramFields(prevProgramFields => ({
      ...prevProgramFields,
      description:event.target.value
    }));
  }

  function handleInputChange(event, index) {
    const {name,value,selectedOptions} = event.target;
    // Update an existing exercise
    if(index !== undefined){
      let copyOfUserExercises= {...userExercises};
      // split string into array
      if (value.includes(",")) {
        copyOfUserExercises.userExercises[index][name]  = value.split(",");
      }else{
          copyOfUserExercises.userExercises[index][name] = value; 
      }
    }else{
    // create copy of obj
    let copyOfProgram = { ...programRef.current };
    if (name==="categories"){
      const selectedValues = Array.from(selectedOptions).map((option) => option.value);
      copyOfProgram[name] = selectedValues; 
    }else{
      copyOfProgram[event.target.name] = event.target.value;
    }
    //Update Global State
    updateProgramFields(copyOfProgram);
    }
  }
  function handleEditProgram(program) {
    // Don't display the warning for new program creation.
    console.log(program)
    if(program.programID !==""){
      alert("IMPORTANT!! EDITING PROGRAM CAN BREAK WORKOUT HISTORY COMPATIBILITY AND LOGIC IF SETS OR STRUCTURE ALTERED.");
    }
    updateEditorPage({
      "workouts": true,
      "programName": false,
      "workoutEditor": false,
      "logic": false,
      "programs": false,
      "name": false,
      "mainButtons": true,
      "exerciseEditor": false,
      "newExercise": false
    });
    // Deep copy, otherwise copyOfPrograms is referencing userPrograms directly and mutating it causing bugs.
    // This method is ok here, since no functions in object. Does have limitations. See javascript docs.
    let programCopy = JSON.parse(JSON.stringify(userPrograms));
    let chosenProgram = programCopy.userPrograms.find(
      ({ programID }) => programID === program.programID
    );
    if (chosenProgram !== undefined) {
      updateProgramFields(chosenProgram);
      // GRAB ALL WORKOUTS AND DISPLAY AS CARDS
      if (chosenProgram.workoutsInProgram.length > 0) {
        createWorkoutCards(chosenProgram.workoutsInProgram);
      } else {
        setWorkoutCards("");
      }
    }
    else {
      // create new program structure
      let newProgram = { ...newProgramFields }
      newProgram.programID = generateUniqueExerciseID("program", userPrograms.userPrograms);
      newProgram.programName = "New Program";
      //update local state
      updateProgramFields(newProgram);
      setWorkoutCards("No Workouts To Display");
      return;
    }
  }

  function handleEditWorkout(event) {
    //event.stopPropagation();
    let copyOfProgram = { ...programRef.current };

    updateEditorPage({
      "workouts": false,
      "programName": false,
      "workoutEditor": true,
      "logic": false,
      "programs": false,
      "name": false,
      "mainButtons": true,
      "exerciseEditor": false,
      "newExercise": false
    });
    // GRAB PROGRAM DEETS
    if (copyOfProgram !== 0) {
      let workoutSelected = copyOfProgram.workoutsInProgram.find(
        ({ workoutID }) => workoutID === event.target.id
      );
      if (workoutSelected !== undefined) {
        setCurrentWorkout(workoutSelected);
      } else {
        // generate new workout
        let workoutTemplate = {
          "workoutID": generateUniqueExerciseID("workout", copyOfProgram.workoutsInProgram),
          "workoutName": "New Workout",
          "workoutExercises": []
        }
        copyOfProgram.workoutsInProgram.push(workoutTemplate);
        updateProgramFields(copyOfProgram);
        setCurrentWorkout(workoutTemplate);
        //refresh workout card display
        createWorkoutCards(copyOfProgram.workoutsInProgram);
      }
    }
  }

  function deleteWorkout(event) {
    let copyOfProgram = { ...programRef.current };
    let response = window.confirm("Delete This workout?");
    if (response) {
      let workoutIndex = copyOfProgram.workoutsInProgram.findIndex(({ workoutID }) => workoutID === event.target.id
      )
      copyOfProgram.workoutsInProgram.splice(workoutIndex, 1);
      // Delete all occurrences in the workoutSchedule
      copyOfProgram.workoutSchedule = copyOfProgram.workoutSchedule.filter(
        (workoutID ) => workoutID !== event.target.id
      );
      updateProgramFields(copyOfProgram);
      // GRAB ALL WORKOUTS AND DISPLAY AS CARDS
      if (copyOfProgram.workoutsInProgram.length > 0) {
        createWorkoutCards(copyOfProgram.workoutsInProgram);
      }
      else {
        setWorkoutCards("");
      }
    }
  }

  function deleteProgram(event) {
    event.preventDefault();
    let allProgramsCopy = { ...userPrograms };
    let response = window.confirm(`Delete This Program? ${programRef.current.programName}`);
    if (response) {
      let programIndex = allProgramsCopy.userPrograms.findIndex(({ programID }) => programID === programRef.current.programID);
      allProgramsCopy.userPrograms.splice(programIndex, 1);
      // Update Global State in Context Api
      updateUserPrograms(allProgramsCopy);
      // Update Workout History in Database
      updateDbPrograms(allProgramsCopy);
      updateEditorPage(
        {
          "workouts": false,
          "programName": false,
          "workoutEditor": false,
          "logic": false,
          "programs": true,
          "name": false,
          "mainButtons": false,
          "exerciseEditor": false,
          "newExercise": false
        }
      );
    }
  }

 // Updates the Workout History in DB and Workout History in React Context Api
 const  updateDbPrograms = async (allPrograms) => {
  const url = domain + '/updatePrograms';
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`,
    },
    credentials: 'include', // Set this to true to include cookies in the request
    body: JSON.stringify(allPrograms),
  };
  try {
    const response = await offlineFetch(url, options, 'userPrograms', allPrograms);
    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.error || "User search failed";
      throw new Error(errorMessage);
    } else{
      // success
      console.log("Program saved successfully")
      console.log(response);
    }

  } catch (error) {
    console.log(error);
    // Handle error scenario, display an error message, etc.
    throw error;
  }
};

  function handleSave(event) {
    event.preventDefault();
    let allPrograms = { ...userPrograms };
    let oldProgram = allPrograms.userPrograms.findIndex(({ programID }) => programID === programFields.programID);
    // New program may have been created, or previous one edited
    if (oldProgram !== -1) {
      //old program, just update
      allPrograms.userPrograms[oldProgram] = programFields;
    } else {
      // newly created program, not yet in user programs
      allPrograms.userPrograms.push(programFields);
    }
    // update global app state in Context api
    updateUserPrograms(allPrograms);
    // Update Workout History in Database
    updateDbPrograms(allPrograms);
    // GRAB ALL WORKOUTS AND DISPLAY AS CARDS
    if (programFields.workoutsInProgram.length > 0) {
      createWorkoutCards(programFields.workoutsInProgram);
    }
    else {
      setWorkoutCards("");
    }
    alert("Program Saved");
    updateEditorPage({
      "workouts": true,
      "programName": false,
      "workoutEditor": false,
      "logic": false,
      "programs": false,
      "name": false,
      "mainButtons": true,
      "exerciseEditor": false,
      "newExercise": false
    });
  }

  // Reset the slider
  useEffect(() => {
    if (userPrograms.userPrograms) { displayAllPrograms(); console.log("resetting slider"); console.log(userPrograms);}
    else{console.log("no user programs to reset slider")}
  }, [userPrograms]);

  // Reset slider when window resized. BROKEN 7-9-23
  useEffect(() => {
    // Callback function to handle window resize
    const handleResize = () => {
      // Perform actions when window is resized
      displayAllPrograms();
      console.log("Window resized!");
    };
    // Attach the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run the effect only once

  //Display all programs
  const displayAllPrograms = () => {
    // Return a Carousel of all programs. Adding key to force rerender. Not efficient.
    return <Slider key={Math.random()} slides={userPrograms.userPrograms} onClickHandler={handleEditProgram} lastProgram={undefined}/>;
  };

  // RENDER THE COMPONENT

  return (
    <div id="container">
      <h1>Program Editor</h1>
      <div id="programName">
        <div
          style={{ display: editorPage["mainButtons"] ? "block" : "none" }}
        >
          <h2>
            Selected Program: {programFields && programFields.programName}
            {programFields && (
            <i id="edit-program-name"
              className="fa fa-pencil"
              style={{padding: "10px",fontSize: "20px" }}
              aria-hidden="true"
              onClick={(event) => updateEditorPage(Object.assign({ ...editorPage }, { "programName": !editorPage.programName }))}>
            </i>)}
          </h2>
          <div id="programNameInput" style={{ display: editorPage.programName ? "block" : "none" }}>
          <input
            type="text"
            required
            className="programName form-control"
            id="programName"
            name="programName"
            value={programFields?programFields.programName:""}
            onChange={(event) => handleProgramNameChange(event)}
          />
        </div>
        <div id="programAuthorInput" style={{ maxWidth:"50%",margin:"auto"}}>
        <label>Author:</label>
          <input
            type="text"
            required
            className="author form-control"
            id="author"
            name="author"
            value={programFields?programFields.author:""}
            onChange={(event) => handleInputChange(event)}
          />
        </div>
        <div id="programDescription"
        style={{ display: editorPage["workoutEditor"] ? "none" : "block" }}>
        <div className="form-group program-details">
        <div className="row">
          <div className="col">
            <label><strong>Program Description: </strong></label>
            <span> Enter useful info about your program, such as which days, or the suggested rep scheme, as well as how the logic works, if any.</span>
          </div>
          <div className="col">
            <label><strong>Categories: </strong></label>
            <span> Select Which Categories best describe this Program. Program Search will rely on this.</span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <textarea
              className="form-control large-textarea"
              id="programDescription"
              name="programDescription"
              type="text"
              value={programFields?programFields.description:""}
              onChange={(event)=>editDescription(event)}
            />
          </div>
          <div className="col">
            <select
              multiple
              id="categories"
              defaultValue={programFields?programFields.categories:[]}
              name="categories"
              onChange={(event) => handleInputChange(event)}
            >
              <option key="strength" value="strength">Strength</option>
              <option key="stretching" value="stretching">Stretching</option>
              <option key="cardio" value="cardio">Cardio</option>
              <option key="plyometrics" value="plyometrics">Plyometrics</option>
              <option key="strongman" value="strongman">Strongman</option>
              <option key="powerlifting" value="powerlifting">Powerlifting</option>
              <option key="olympic_weightlifting" value="olympic_weightlifting">Olympic Weightlifting</option>
              <option key="calisthenics" value="calisthenics">Calisthenics</option>
            </select>
          </div>
        </div>
        </div>
        </div>
        </div>
        <hr />
      </div>
      <div
        id="programName"
        style={{ display: editorPage["name"] ? "block" : "none" }}
      ><input></input></div>
      <p
        style={{ display: editorPage["programs"] ? "block" : "none" }}
      >
        Select a program to edit, or create a new one. Click Share to send a program to a fellow user.
        <br/>
        <button
          className="dashboard-btn"
          id="new-program"
          onClick={() => handleEditProgram({programID:""})}
        >
          Create
        </button>
        <ShareModal />
        </p>
      <div
        id="programs"
        className="program-editing-container"
        style={{ display: editorPage["programs"] ? "flex" : "none" }}
      >
      {userPrograms && userPrograms.userPrograms && 
      userPrograms.userPrograms.length > 0 && (displayAllPrograms())}
      </div>
      <div
        id="mainButtons"
        style={{ display: editorPage["mainButtons"] ? "block" : "none" }}>
        <button
          className="dashboard-btn"
          onClick={() => updateEditorPage(
            {
              "workouts": true,
              "programName": false,
              "workoutEditor": false,
              "logic": false,
              "programs": false,
              "name": false,
              "mainButtons": true,
              "exerciseEditor": false,
              "newExercise": false
            })
          }>Workouts</button>
        <button
        className="dashboard-btn"
          onClick={() => {
            updateEditorPage({
              "workouts": false,
              "programName": false,
              "workoutEditor": false,
              "logic": true,
              "programs": false,
              "name": false,
              "mainButtons": true,
              "exerciseEditor": false,
              "newExercise": false
            })}
          }>Logic</button>
        <button
        className="dashboard-btn"
          onClick={() => updateEditorPage(
            {
              "workouts": false,
              "programName": false,
              "workoutEditor": false,
              "logic": false,
              "programs": true,
              "name": false,
              "mainButtons": false,
              "exerciseEditor": false,
              "newExercise": false
            })
          }>All Programs</button>
          <button className="dashboard-btn">
          <i
            className="fa fa-trash"
            aria-hidden="true"
            onClick={(event) => deleteProgram(event)}
          ></i>
          </button>
      </div>
      <div
        id="workouts"
        style={{ display: editorPage["workouts"] ? "block" : "none" }}
      >
        <div id="workout-card-container">
          {workoutCards !== "" ? workoutCards : ""}
          <div
            id={"new WorkoutID not yet in system"}
            className="workout-card"
          >
            <h4>Add Workout</h4>
            <div className="btn-container">
            <button className="dashboard-btn"
              id={"new WorkoutID not yet in system"}
              onClick={(event) => handleEditWorkout(event)}
            >
              <i
                id={"new WorkoutID not yet in system"}
                className="fa fa-plus"
                aria-hidden="true"
                style={{ textAlign: "center" }}
              ></i>
            </button>
            </div>
          </div>
        </div>
        <div id="workout-schedule-area">
        {programFields && (<WorkoutScheduler programFields={programFields} updateProgramFields={updateProgramFields}
        /*toggleLogicEditor={toggleLogicEditor}*/ />)}
        </div>
      </div>
      <div
        id="workoutEditor"
        style={{ display: editorPage["workoutEditor"] ? "block" : "none" }}
      >
        {editorPage["workoutEditor"] ? (
          <WorkoutForm
            currentWorkout={currentWorkout}
            setCurrentWorkout={setCurrentWorkout}
            programFields={programFields}
            updateProgramFields={updateProgramFields}
          />
        ) : (
          ""
        )}
      </div>
      <div
        id="logic"
        style={{ display: editorPage["logic"] ? "block" : "none" }}>
        {editorPage["logic"] && programFields && programFields.workoutSchedule.length>0?<ModifyLogic programFields={programFields} updateProgramFields={updateProgramFields}/>
        : (
            <p>
              Please create a Workout Schedule first by clicking on Workouts. Logic applies to specific exercises in specific workouts in the workout schedule.
              Workout history available to analyze will be limited to workouts prior to the workout day on which logic is applied.
              Analyze each set, which is known because the workouts have been created. If you wish to change number of sets, there is no way to currently do that,
              except to add a similar workout into the workout schedule with a varying number of sets. Deload workouts can also be programmed into the workout schedule,
              as well as different phases of the program (program blocks or cycles, such as meso cycles, etc...)
              The array starts at 0, which is true for all array index references (applyToWorkoutScheduleIndex, analyzeWorkoutScheduleIndex, sets).
              You need to figure out how to keep track of the last lift rep and weight values.
              Starting Strength looks as workouts A, B, A then adds weight to B of the next week.
              setsToAnalyze: [0, 1] means set 1 and set 2.
              If all conditions must be met in the analyze array, allOrAnyAnalyze should be set to "all".
              Likewise, for each if statement using allOrAnyIfs.
            </p>
          )}
      </div>
      <div className="submit-button"
        style={{ display: editorPage["mainButtons"] ? "block" : "none" }}>
        <br />
        <button
          className="dashboard-btn"
          type="submit"
          onClick={(event) => handleSave(event)}>
          Save
        </button>
      </div>
    </div>
  );
}

export default ProgramCreator;
