import React from 'react';
import './LoadingModal.css'; // Import your CSS file for styling

function LoadingModal() {
  return (
    <div className="loading-modal">
      <div className="loading-content">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );
}

export default LoadingModal;
