import React, { createContext, useState, useEffect } from 'react';

// Create the app context
export const AppStateContext = createContext();

// Create the context provider component
export const AppStateProvider = ({ children }) => {
  // Define your app's global state. Updated with DB call in Login
  const getLocalStorageWithExpiration = (key) => {
    const item = JSON.parse(localStorage.getItem(key));
    if (item) {
      const now = new Date().getTime();
      if (now < item.expiration) {
        return item.value;
      } else {
        // The data has expired, remove it from localStorage and return null
        localStorage.removeItem(key);
        return null;
      }
    }
    return null;
  };
  
  // Check if data is stored in local storage and parse it if available
  const storedUser = getLocalStorageWithExpiration('user');
  const storedUserPrograms = getLocalStorageWithExpiration('userPrograms');
  const storedUserExercises = getLocalStorageWithExpiration('userExercises');
  const storedUserWorkoutHistory = getLocalStorageWithExpiration('userWorkoutHistory');
  const storedTTExercises = getLocalStorageWithExpiration('TTExercises');
  const storedPendingRequests = getLocalStorageWithExpiration('pendingRequests');
  const storedJWT = getLocalStorageWithExpiration('jwt');

  // Define your app's global state and use stored data if available
  // Contains all User deets
  const [user, setUser] = useState(storedUser || null);
  // All programs owned by user. Contains Program Logic and Workout Structure
  const [userPrograms, setUserPrograms] = useState(storedUserPrograms || []);
  // All exercises owned by user
  const [userExercises, setUserExercises] = useState(storedUserExercises || "");
  // All Workout History owned by user
  const [userWorkoutHistory, setWorkoutHistory] = useState(storedUserWorkoutHistory || "");
  // is user logged in? https://serverless-stack.com/chapters/add-the-session-to-the-state.html
  const [isAuthenticated, userHasAuthenticated] = useState(storedUser? true:false);
  const [jwt, setJWT] = useState(storedJWT || "");
  // Built-In Template Trainer Exercises
  const [ttExercises,setTTExercises] = useState(storedTTExercises || null); 

  const [pendingRequests,setPendingRequests] = useState(storedPendingRequests || null); 
  // Define functions to update the state
  const updateUser = (userData) => {
    setUser(userData);
  };
  const updateUserPrograms = (programs) => {
    setUserPrograms(programs);
  };
  const updateUserExercises = (exercises) => {
    setUserExercises(exercises);
  };
  const updateUserWorkoutHistory = (workoutHistory) => {
    console.log('updateUserWorkoutHistory called with:', workoutHistory);
    setWorkoutHistory(workoutHistory);
  };
  const updateAuth= (auth) => {
    userHasAuthenticated(auth);
  };

  const updateJWT= (token) => {
    setJWT(token);
  };
  const updateTTExercises = (ttExerciseLibrary) => {
    setTTExercises(ttExerciseLibrary);
  };
  const updatePendingRequests = (sharedPrograms) => {
    setPendingRequests(sharedPrograms);
  };
  // Function to set an item in local storage with an expiration time
  const setLocalStorageWithExpiration = (key, value, expirationInMs) => {
    const now = new Date();
    const item = {
      value: value,
      expiration: now.getTime() + expirationInMs,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };
    // Clear all values and local storage
    const clearAppState = () => {
      setUser(null);
      setUserPrograms([]);
      setUserExercises("");
      setWorkoutHistory("");
      userHasAuthenticated(false);
      setJWT(null);
      setTTExercises(null);
      setPendingRequests(null);
      localStorage.clear();
    };
    // Update localStorage when user changes
    useEffect(() => {
      setLocalStorageWithExpiration("user", user, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    }, [user]);

    // Update localStorage when userPrograms change
    useEffect(() => {
      setLocalStorageWithExpiration("userPrograms", userPrograms, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    }, [userPrograms]);
  
    // Update localStorage when userExercises changes
    useEffect(() => {
      setLocalStorageWithExpiration("userExercises", userExercises, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    }, [userExercises]);
    useEffect(() => {
      setLocalStorageWithExpiration("TTExercises", ttExercises, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    }, [ttExercises]);
  
    // Update localStorage when userWorkoutHistory changes
    useEffect(() => {
      setLocalStorageWithExpiration("userWorkoutHistory", userWorkoutHistory, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    }, [userWorkoutHistory]);

    useEffect(() => {
      setLocalStorageWithExpiration("pendingRequests", pendingRequests, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    }, [pendingRequests]);

    useEffect(() => {
      setLocalStorageWithExpiration("jwt", jwt, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    }, [jwt]);

    // Ping the server at regular intervals to keep it active
    const pingInterval = 10 * 60 * 1000; // 10 minutes
    const domain = "https://api.templatetrainer.com";

    useEffect(() => {
      const pingServer = async () => {
        try {
          const response = await fetch(domain+'/ping'); // Adjust the URL based on your server configuration
          if (response.ok) {
            console.log('Server ping successful.');
          } else {
            console.error('Server ping failed.');
          }
        } catch (error) {
          console.error('Server ping error:', error);
        }
      };

      // Start pinging the server at regular intervals
      const intervalId = setInterval(pingServer, pingInterval);

      // Clear the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, []);

  // Create an object with the state and update functions to be provided as the value
  const appState = {
    user,
    userPrograms,
    userExercises,
    ttExercises,
    userWorkoutHistory,
    isAuthenticated,
    jwt,
    pendingRequests,
    updateUser,
    updateUserPrograms,
    updateUserExercises,
    updateTTExercises,
    updateUserWorkoutHistory,
    updateAuth,
    updateJWT,
    updatePendingRequests,
    clearAppState
  };

  // Provide the app state and update functions to the child components
  return (
    <AppStateContext.Provider value={appState}>
      {children}
    </AppStateContext.Provider>
  );
};
